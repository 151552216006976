import axios from 'axios'
// 文件模板管理请求模块
import request from '@/utils/request'

/**
 * 创建在线文档
 * @param {*} name,bussinessId
 * @returns
 */
 export const createWordOnlineUrl = (name,bussinessId) => {
  return request({
    method: 'POST',
    url: `/wos/api/FileManagement/CreateFile`,
    data:{
      Name:name,
      BussinessId:bussinessId
    }
  })
}
/**
 * 获取在线文档URL
 * @param {*} fileId,edit
 * @returns
 */
 export const getWordOnlineUrl = (fileId,edit) => {
  return request({
    method: 'POST',
    url: `/wos/api/FileManagement/ViewFile`,
    data:{
      FileId:fileId,
      Edit:edit
    }
  })
}
/**
 * 下载word在线文档
 * @param {*} fileId,edit
 * @returns
 */
 export const downloadWordOnline = (fileId) => {
  axios.post('/wos/api/FileManagement/DownLoadFile',{
      FileId:fileId
        },{
        responseType:'blob',
      },{
        headers: {
              "Content-Type": "application/json;charset=UTF-8",
      },
      })
    .then((response)=>{
      console.log(response);
      //headers请求头的乱码数据转换为中文
      // const fileName = decodeURI(response.headers['content-disposition'].split(';')[1].split('=')[1]) 
      //下载文件
      let blob = new Blob([response.data], {type: "application/vnd.ms-excel"})
          let objectUrl = URL.createObjectURL(blob) // 创建URL
          const link = document.createElement('a')
          link.href = objectUrl
          // link.download = '.docx'// 自定义文件名
          //这里是获取后台传过来的文件名
          link.setAttribute("download",fileId)
          link.click() // 下载文件
          URL.revokeObjectURL(objectUrl) // 释放内存
    })
    .catch((error)=>{
      console.log(error);
      alert('下载失败')
    })
}