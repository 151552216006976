<template>
  <el-form ref="form" :model="inquiryEditForm" label-width="110px" size="mini">
    <el-form-item label="询问通知书模板">
      <el-input v-model="inquiryEditForm.questionConfigId"></el-input>
    </el-form-item>
    <el-form-item label="印章库">
      <el-input v-model="inquiryEditForm.questionContent"></el-input>
    </el-form-item>
    <el-form-item label="询问通知书">
      <el-input v-model="inquiryEditForm.sealPhotoPath"></el-input>
    </el-form-item>
    <el-button type="primary" @click="inquiryEditSaveBtn">保存</el-button>
  </el-form>
</template>

<script>
import { editReady } from '@/api/case'

export default {
  name: 'edit',
  components: {
    
  },
  props: {
    personId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      inquiryEditForm: {
        questionConfigId: '询问通知书模板',
        questionContent: '印章库',
        sealPhotoPath: '通知书'
      }
    }
  },
  methods: {
    inquiryEditSaveBtn () {
      let search={
        id: this.personId,
        items: this.inquiryEditForm
      }
      editReady(search).then(res => {
        console.log("editReady",res)
      })
      this.$message({
        message: '恭喜你，保存成功！',
        type: 'success'
      })
    }
  }
}
</script>

<style scoped>
.select-type {
  display: flex !important;
}
</style>
