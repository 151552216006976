// 文章相关请求模块
import request from '@/utils/request'

// 根据当事人id获取当事人
export const getPersonId = id => {
  return request({
    method: 'GET',
    url: `/api/PersonConcerned/${id}`
  })
}
// 添加当事人
export const addPerson = data => {
  return request({
    method: 'POST',
    url: '/api/PersonConcerned/add',
    data
  })
}
// 获取案件当事人列表
export const getPersonList = params => {
  return request({
    method: 'GET',
    url: '/api/PersonConcerned/query',
    params
  })
}
// 删除当事人
export const deletePeople = deleteId => {
  return request({
    method: 'DELETE',
    url: '/api/PersonConcerned/delete',
    data: {
      id: deleteId
    }
  })
}
// 编辑当事人
export const editPeople = (personId, data) => {
  return request({
    method: 'PUT',
    url: '/api/PersonConcerned/edit',
    params: {
      id: personId
    },
    data
  })
}
// 根据案件id获取当事人
export const getPeopleId = id => {
  return request({
    method: 'GET',
    url: `/api/PersonConcerned/${id}`
  })
}

// 设置开始签章(目前设置需状态为 问询完毕后（InquiryStatus=4），设置签章)
export const concernedSign = data => {
  return request({
    method: 'PUT',
    url: `/api/PersonConcerned/sign`,
    data
  })
}

//设置问询完毕(目前设置需状态为 创建会议室后（InquiryStatus=3），设置问询完毕)
export const completeInquiry = data => {
  return request({
    method: 'PUT',
    url: `/api/PersonConcerned/completeinquiry`,
    data
  })
}

export const signPersonList = caseId => {
  return request({
    method: 'GET',
    url: `/api/PersonConcerned/SignPersonList`,
    params:{
      CaseId : caseId
    }
  })
}
