<template>
  <div class="article-container sf">
    <div slot="header" class="clearfix sf-breadcrumb">
      <!--面包屑-->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>案件管理</el-breadcrumb-item>
        <el-breadcrumb-item>待办案件</el-breadcrumb-item>
        <el-breadcrumb-item>案件详情</el-breadcrumb-item>
      </el-breadcrumb>
      <!--/面包屑-->
    </div>
    <el-card class="filter-card">

      <!--数据表格筛选-->
      <el-form ref="detailForm" :model="search" label-width="40px">
        <el-row :gutter="10">
          <el-col :span="7">
            <el-input placeholder="请输入姓名" v-model="search.keyword" class="input-with-select">
              <el-button slot="append" type="warning" icon="el-icon-search" @click="loadPeopleBySearch()"></el-button>
            </el-input>
          </el-col>
          <el-col :span="17" class="text-align-r">
            <el-button type="warning" icon="el-icon-finished" v-if="caseStatus == 0 && caseSignFlow.FlowStatus == 2" @click="caseFinish">案子办结</el-button>
            <el-button type="warning" icon="el-icon-document-checked" v-if="caseSignFlow.FlowStatus == -1 || caseSignFlow.FlowStatus == 0 || caseSignFlow.FlowStatus == 3" @click="handleCaseSign">协议签署</el-button>
            <el-button type="warning" icon="el-icon-document-delete" v-if="caseSignFlow.FlowStatus == 1" @click="caseSignFlowRevoke">待签署协议撤回</el-button>
            <el-button type="warning" icon="el-icon-view" v-if="caseSignFlow.FlowStatus == 1 || caseSignFlow.FlowStatus == 2" @click="dialogSignDetailVisible = true">查看协议签署</el-button>
            <el-button type="warning" icon="el-icon-plus" v-if="caseStatus == 0 && caseSignFlow.FlowStatus != 1 && caseSignFlow.FlowStatus != 2" @click="dialogPeoVisible = true">新增当事人</el-button>
            <el-button class="el-icon-back" @click="back">返回</el-button>
          </el-col>
        </el-row>
      </el-form>
      <!--/数据表格筛选-->

      <!--数据表格-->
      <el-table ref="tableRef" :height="tableHeight" :data="tableData" border v-loading="loading"
                style="width: 100%; margin: 20px 0">
        <el-table-column prop="CaseId" v-model="tableData.CaseId" align="center" label="序号" width="60">
          <template slot-scope="scope">
            <span v-text="getIndex(scope.$index)"> </span>
          </template>
        </el-table-column>
        <el-table-column prop="Name" v-model="tableData.Name" align="center" label="当事人姓名" width="180">
          <template slot-scope="scope">
              <a @click="handleUpdate(scope.row.PersonId,scope.row.InquiryStatus)" class="nameClass">{{scope.row.Name}}</a>
            </template>
        </el-table-column>
        <el-table-column prop="PersonType" v-model="tableData.PersonType" align="center" label="当事人类型">
        </el-table-column>
        <el-table-column prop="CardType" v-model="tableData.CardType" align="center" label="身份类型">
        </el-table-column>
        <el-table-column prop="IDCard" v-model="tableData.IDCard" align="center" label="证件号码">
        </el-table-column>
        <el-table-column prop="Phone" v-model="tableData.Phone" align="center" label="联系电话">
        </el-table-column>
        <el-table-column prop="InquiryStatus" align="center" label="询问状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.InquiryStatus === 0" type="info">待配置</el-tag>
            <el-tag v-if="scope.row.InquiryStatus === 1" type="">待准备</el-tag>
            <el-tag v-if="scope.row.InquiryStatus === 2" type="success">待创建会议室</el-tag>
            <el-tag v-if="scope.row.InquiryStatus === 3" type="">待询问</el-tag>
            <el-tag v-if="scope.row.InquiryStatus === 4" type="success">已询问</el-tag>
            <el-tag v-if="scope.row.InquiryStatus === 5" type="success">待当事人签章</el-tag>
            <el-tag v-if="scope.row.InquiryStatus === 8" type="success">已签章</el-tag>
            <el-tag v-if="scope.row.InquiryStatus === 9" type="success">签章已撤回</el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="MeetingNum" v-model="tableData.ModifyTime" align="center" label="被询问次数" width="180">
        </el-table-column> -->
        <el-table-column prop="CreateTime" v-model="tableData.CreateTime" align="center" label="创建时间" width="180">
        </el-table-column>
        <el-table-column prop="peoOperate" align="center" label="操作" width="280">
          <div class="icon-box" slot-scope="scope">
            <a @click="inquiryBtn(scope.row)" class="btn-view" v-if="scope.row.InquiryStatus === 0"><i
              class="el-icon-setting"></i>配置询问事项</a>
            <a @click="handle(scope.row)" v-if="scope.row.InquiryStatus === 1" class="btn-view"><i
              class="el-icon-document-checked"></i>问前准备</a>
            <a @click="create(scope.row)" class="btn-view" v-if="scope.row.InquiryStatus === 2"><i
              class="el-icon-video-play"></i>创建会议室</a>
            <a @click="process(scope.row)" class="btn-view" v-if="scope.row.InquiryStatus === 3"><i
              class="el-icon-chat-line-round"></i>开始询问</a>
            <a @click="process(scope.row)" class="btn-view" v-if="scope.row.InquiryStatus === 4 || scope.row.InquiryStatus === 9"><i
              class="el-icon-chat-line-round"></i>发起签章</a>
            <a @click="process(scope.row)" class="btn-view" v-if="scope.row.InquiryStatus === 5 || scope.row.InquiryStatus === 8"><i
              class="el-icon-chat-line-round"></i>查看</a>
            <a @click="materialList(scope.row.CaseId,scope.row.PersonId)" class="btn-view"><i class="el-icon-view"></i>证据材料</a>
            <a @click="handleVideoList(scope.row.PersonId)" class="btn-view"><i class="el-icon-view"></i>会议视频</a>
            <!-- <a @click="handleUpdate(scope.row.PersonId)" class="btn-edit"><i class="el-icon-edit">编辑</i></a> -->
            <!-- <a @click="deleteBtn(scope.row)" class="btn-delete"><i class="el-icon-delete">删除</i></a> -->
          </div>
        </el-table-column>
      </el-table>
      <!--/数据表格-->
      <!--分页条-->
      <el-pagination
        class="pagination"
        background
        layout="total, sizes, prev, pager, next"
        :disabled="loading"
        @size-change="changePageSize"
        @current-change="changeCurrentPage"
        :current-page="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total">
      </el-pagination>
      <!--/分页条-->
    </el-card>
    <el-dialog class="increaseForm" title="新增人员" append-to-body :visible.sync="dialogPeoVisible" v-if="dialogPeoVisible"
               :close-on-click-modal="false">
      <people :dialogPeoVisible="dialogPeoVisible" :CaseId="search.CaseId" @dialogChanged="IncreaseDataChange($event)"/>
    </el-dialog>
    <el-dialog class="increaseForm" v-if="dialogInquiryVisible" title="配置询问事项" append-to-body
               :visible.sync="dialogInquiryVisible"
               :close-on-click-modal="false">
      <inquiry :dialogInquiryVisible="dialogInquiryVisible" :PersonId="PersonId"
               :InquiryStatus="tableData.InquiryStatus"
               @editChanged="inquiryDataChange"/>
    </el-dialog>
    <el-dialog class="increaseForm" v-if="dialogDetailEditVisible" title="编辑" append-to-body
               :visible.sync="dialogDetailEditVisible"
               :close-on-click-modal="false">
      <detail-edit :dialogDetailEditVisible="dialogDetailEditVisible" :PersonId="tableData.PersonId" :InquiryStatus="tableData.InquiryStatus"
                   @editChanged="editDataChange($event)"/>
    </el-dialog>
        <el-dialog class="" v-if="dialogSignVisible" title="协议签署" append-to-body :visible.sync="dialogSignVisible"
               :close-on-click-modal="false">
      <agreement-sign :caseId="search.CaseId"  @dialogChanged="agreementSignChange($event)"/>
    </el-dialog>
    <el-dialog class="" v-if="dialogSignDetailVisible" title="协议签署详情" append-to-body :visible.sync="dialogSignDetailVisible"
               :close-on-click-modal="false">
      <agreement-sign-detail :caseId="search.CaseId"/>
    </el-dialog>
        <el-dialog class="" v-if="dialogVideoListVisible" title="视频会议" append-to-body :visible.sync="dialogVideoListVisible"
               :close-on-click-modal="false">
      <video-list :caseId="search.CaseId" :personId="tableData.PersonId"/>
    </el-dialog>
  </div>
</template>

<script>
import {Constants} from "@constants";
import People from './components/people'
import Inquiry from './components/inquiry'
import DetailEdit from './components/detail-edit'
import AgreementSign from './components/agreement-sign'
import AgreementSignDetail from './components/agreement-sign-detail'
import VideoList from './components/video-list'
import { getPersonList, deletePeople } from '@/api/personConcerned'
import { caseSignQuery,caseSignRevoke } from '@/api/caseSign'
import { flowDocumentDownLoad } from '@/api/signFlow'
import { getCaseId,caseFinish } from '@/api/case'

export default {
  name: 'Detail',
  components: {
    People,
    Inquiry,
    DetailEdit,
    AgreementSign,
    AgreementSignDetail,
    VideoList
  },
  props: {},
  data () {
    return {
      PersonId: 0,
      caseStatus: '',
      tableData: [],
      input1: '',
      isShow: false,
      dialogPeoVisible: false,
      dialogInquiryVisible: false,
      dialogSignVisible: false,
      dialogSignDetailVisible: false,
      dialogVideoListVisible: false,
      loading: true,
      tableHeight: 500,
      dialogDetailEditVisible: false,
      // 查询条件
      search: {
        page: 1,
        pageSize: 10,
        keyword: '',
        CaseId: 0
      },
      totalPages: 0,
      // 分页信息
      pageInfo: {
        total: 0, // 总记录数，根据接口获取数据长度(注意：这里不能为空)
        pageSize: 10, // 默认每页数据量
        currentPage: 1
      },
      refresh1: true,
      caseSignFlow : {
        FlowStatus : -1
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.loadPeople(1)
  },
  mounted () {
    this.$nextTick(function () {
      this.tableHeight = window.innerHeight - this.$refs.tableRef.$el.getBoundingClientRect().top - 137
      const that = this
      window.onresize = function () {
        that.tableHeight = window.innerHeight - that.$refs.tableRef.$el.getBoundingClientRect().top - 137
      }
    })
  },
  methods: {
    refresh () {
      this.refresh1 = false
      this.$nextTick(() => {
        this.refresh1 = true
      })
    },
    loadPeopleBySearch () {
      this.search.page = 1
      this.loadPeople()
    },
    loadPeople (page = 1, tf = false) {
      // 展示加载中 loading
      this.loading = true
      this.search.CaseId = this.$route.query.CaseId
      console.log('search:', this.search)
      getPersonList(this.search).then(res => {
        console.log(res, 'res')
        this.tableData = []
        if (res.data) {
          this.tableData = res.data
          console.log('tableData', this.tableData)
          // 新增完毕
          if (tf) {
            this.search.page = res.totalPages
          }
        }
        // 不管有没有数据，分页都要随着变化
        this.pageInfo.currentPage = res.currentPage
        this.pageInfo.pageSize = res.pageSize
        this.pageInfo.total = res.total
        // 关闭加载中 loading
        this.loading = false
      })
        caseSignQuery({
            CaseId:this.search.CaseId,
            IncludeDetails:true
        }).then(res => {
          if(res.data){
            this.caseSignFlow = res.data[res.data.length-1]
            console.log('caseSignFlow',this.caseSignFlow)
          }
        })
        getCaseId(this.search.CaseId).then(res => {
            this.caseStatus = res.Status
        })

    },
    resetForm () {
      this.search.name = null
      this.search.idCard = null
      this.search.cardType = null
    },
    // 高级搜索框开关
    toggle () {
      this.isShow = !this.isShow
    },
    create (val) {
      this.$router.push('/case/video?CaseId=' + val.CaseId + '&&PersonId=' + val.PersonId)
    },
    inquiryBtn (val) {
      console.log('inquiryBtn', val)
      this.PersonId = val.PersonId
      this.dialogInquiryVisible = true
    },
    handleUpdate (PersonId,InquiryStatus) {
      this.tableData.PersonId = PersonId
      this.tableData.InquiryStatus = InquiryStatus
      this.dialogDetailEditVisible = true
    },
    changePageSize (pageSize) {
      console.log(`每页 ${pageSize} 条`)
      this.search.pageSize = pageSize // 改变每页显示的条数
      this.search.page = 1 // 注意：在改变每页显示的条数时，要将页码显示到第一页
      this.loadPeople() // 点击每页显示的条数时，显示第一页
    },
    changeCurrentPage (page) {
      console.log(`当前第 ${page} 页`)
      this.search.page = page // 改变默认的页数
      this.loadPeople()
    },
    inquiryDataChange (param1) {
      this.dialogInquiryVisible = false// param1
      this.loadPeople()
    },
    editDataChange (params) {
      this.loadPeople(this.search.page)
      this.dialogDetailEditVisible = params
    },
    IncreaseDataChange (params) {
      console.log('IncreaseDataChange', params)
      this.dialogPeoVisible = params
      this.loadPeople(this.search.page)
    },
    agreementSignChange (params) {
      this.dialogSignVisible = params
      this.loadPeople(this.search.page)
    },
    getIndex ($index) {
      return (this.pageInfo.currentPage - 1) * this.pageInfo.pageSize + $index + 1
    },
    handle (val) {
      // console.log("handle",val)
      this.$router.push('/case/plan?CaseId=' + val.CaseId + '&&PersonId=' + val.PersonId)
    },
    process (val) {
      // console.log("process",val)
      this.$router.push('/case/process?CaseId=' + val.CaseId + '&&PersonId=' + val.PersonId)
    },
    deleteBtn (row) {
      this.$confirm('此操作将永久删除该当事人, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deletePeople(row.PersonId).then((res) => {
          // 删除成功，更新当前页的文章数据列表
          // this.loadPeople(this.page)
          // 判断最后一条
          if (this.search.page > 1 && this.tableData.length === 1) {
            this.search.page = this.search.page - 1
          }
          this.loadPeople(this.search.page)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 路由传参
    getID () {
      this.search.CaseId = this.$route.query.CaseId
    },
    back(){
      if(this.caseStatus == 0){
        this.$router.push('/case')
      }else{
        this.$router.push('/cased')
      }
    },
    materialList (caseId,personId) {
      this.$router.push({
        path: '/case/material',
        query: {
          CaseId: caseId,
          PersonId:personId
        }
      })
    },
    handleCaseSign(){
      const data={
        page: 1,
        pageSize: 100,
        CaseId: this.$route.query.CaseId
      }
      getPersonList(data).then(res => {
        if (res.data) {
          let flag = true
            res.data.forEach(item =>{
                if(item.InquiryStatus != 8){
                    flag = false
                }
            })
            if(flag){
              this.dialogSignVisible = true
            }else{
              this.$message({
                type: 'info',
                message: '所有人未都完成签章，无法发起最终协议签署!'
              })
              return
            }
        }else{
          this.$message({
                type: 'info',
                message: '请先添加当事人!'
              })
        }
      })
    },
    async caseSignFlowRevoke () {
      let currentUserInfo = Constants.getSessionStorage(Constants.get("UserInfo"), true);
        await caseSignRevoke({
            flowId: this.caseSignFlow.FlowId,
            operatorId: currentUserInfo.UserId,
            revokeReason: '最终协议撤回'
        }).then(res => {
          this.loadPeople(this.search.page)
        })
    },
    async caseSignFlowDownload(){
      const res = await flowDocumentDownLoad(this.caseSignFlow.FlowId)
      res.docs.forEach(doc => {
        window.open(doc.fileUrl)
      });
    },
    async caseFinish(){
      this.$confirm('确认办结案子？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
          caseFinish(this.search.CaseId).then(res => {
            this.$message({
              type: 'success',
              message: '该案子已办结!'
            })
          this.loadPeople(this.search.page)
      })
      })
    },
    handleVideoList(PersonId) {
      this.tableData.PersonId = PersonId
      this.dialogVideoListVisible = true
    }
  }
}
</script>

<style>
.filter-card {
  margin-bottom: 30px;
}

.list-table {
  margin-bottom: 20px;
}

.article-cover {
  width: 60px;
  background-size: cover;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

.increaseForm {
  text-align: center;
}

.el-select .el-input {
  width: 130px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.sf-breadcrumb {
  padding-bottom: 13px;
  border-bottom: #cbddff 1px solid;
  margin-bottom: 20px;
}

.sf-breadcrumb .el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  font-weight: normal !important;
}

.text-align-r {
  text-align: right;
}

.sf-search-more {
  border: #d8e3f8 1px solid;
  border-radius: 4px;
  padding: 20px 20px 0 20px;
  margin-top: 20px;
  background: #e7effd;
}
.nameClass{
  cursor: pointer;
  text-decoration: underline;
  color:#1e4ca5;
}
</style>
