/**
 * 基于 axios 封装的请求模块
 */
import axios from 'axios'
import qs from 'qs'
import {
  Constants
} from '@constants'
import {
  stringify
} from 'json-bigint'
// import JSONbig from 'json-bigint'
import router from '@/router'

// // 非组件模块可以这样加载使用 element 的 message 提示组件
import {
  Message
} from 'element-ui'
//
// // axios()
// // axios.get()
// // axios.post()
//
// // 创建一个 axios 实例，说白了就是复制了一个 axios
// // 我们通过这个实例去发请求，把需要的配置配置给这个实例来处理
// axios.defaults.baseURL = process.env.VUE_APP_API_URL;
// axios.defaults.options.emulateJSON = true
const request = axios.create({
  // baseURL: process.env.VUE_APP_API_URL, // 请求的基础路径
  responseType: 'json',
  timeout: 30000, // 超时时间
  headers: {
    'Content-Type': 'application/json'
  }
  //   // 定义后端返回的原始数据的处理
  //   // 参数 data 就是后端返回的原始数据（未经处理的 JSON 格式字符串）
  //   transformResponse: [function (data) {
  //     // Do whatever you want to transform the data
  //     // console.log(data)
  //
  //     // 后端返回的数据可能不是 JSON 格式字符串
  //     // 如果不是的话，那么 JSONbig.parse 调用就会报错
  //     // 所以我们使用 try-catch 来捕获异常，处理异常的发生
  //     try {
  //       // 如果转换成功，则直接把结果返回
  //       return JSONbig.parse(data)
  //     } catch (err) {
  //       console.log('转换失败', err)
  //       // 如果转换失败了，则进入这里
  //       // 我们在这里把数据原封不动的直接返回给请求使用
  //       return data
  //     }
  //
  //     // axios 默认在内部使用 JSON.parse 来转换处理原始数据
  //     // return JSON.parse(data)
  //   }]
})

// 请求拦截器
request.interceptors.request.use(
  // 任何所有请求会经过这里
  // config 是当前请求相关的配置信息对象
  // config 是可以修改的
  config => {
    // 尝试获取token信息
    const userToken = Constants.getLocalStorage(Constants.TOKEN)
    // 如果存在token信息，则统一设置token
    if (userToken) {
      config.headers.Authorization = `Bearer ${userToken}`
    }
    // config.headers["Content-Type"] = "application/json";
    // console.log(config.data)
    // console.log(`请求Content-Type为：${config.headers['Content-Type']}`)
    // console.log(`请求数据：${JSON.stringify(config.data)}`)
    // if (config.method === "get") {
    //   // get请求时 后端所需要参数的格式（a=1&b=2）
    //   config.data = qs.stringify(config.data);
    // }
    // 当这里 return config 之后请求在会真正的发出去
    return config
  },
  // 请求失败，会经过这里
  function (error) {
    return Promise.reject(error)
  }
)

// 响应拦截器
// Add a response interceptor
request.interceptors.response.use(function (response) {
  // 所有响应码为 2xx 的响应都会进入这里
  // response 是响应处理
  // 注意：一定要把响应结果 return，否则真正发请求的位置拿不到数据
  // console.log('response',response)
  return response
}, function (error) {
  console.log('错误响应数据response',error.response)
  let message=error.response.data.message
  if(message && message=="有当事人未配置或询问准备，请先设置好"){
    Message({
      type: 'warning',
      message: '有当事人未配置或询问准备，请先设置好'
    })
  }
  if(message && message=="流程状态错误，FlowStatus=2"){
    Message({
      type: 'warning',
      message: '已经签章完成，无法撤回！'
    })
  }
  if(message && message=="短信验证码发送失败"){
    Message({
      type: 'warning',
      message: '手机号不存在'
    })
  }
  let errorCodes = error.response.data.errors.Code
  if(errorCodes && errorCodes.length > 0){
    Message({
      type: 'warning',
      message: '图形验证码错误！'
    })
    return
  }
  const {
    status
  } = error.response
  const {
    code
  } = error.Code
  // 任何超出 2xx 的响应码都会进入这里
  if (status === 401) {
    // 跳转到登录页面
    // 清除本地存储中的用户Token信息
    Constants.removelocalStorage(Constants.TOKEN)
    router.push({
      name: 'login'
    })
    Message.error('登录状态无效，请重新登录')
  } else if (status === 403) {
    // token 未携带或已过期
    Message({
      type: 'warning',
      message: '没有操作权限'
    })
  } else if (status === 400 || status === 422) {
    // 客户端参数错误
    Message.error(code)
  } else if (status >= 500) {
    Message.error('服务端内部异常，请稍后重试')
  }
  return Promise.reject(error)
})

// 响应拦截
request.interceptors.response.use(
  (res = {}) => {
    if(res.config.url == "/api/CaseMeeting/edit"&&res.status == 204){
      Message({
        type: 'success',
        message: '会议室编辑成功！'
      })
    }
    // Toast.clear();
    const data = res.data
    // console.log("拦截响应",res)
    // 拦截success成功或未返回success的数据（如查询，是直接返回数据的）
    if (res.status === 204) {
      return Promise.resolve(data)
    } if (res.status === 201) {
      return Promise.resolve(res.headers)
    } else {
      if (data.message) {
        // Toast(data.message);
        // vm.$toast.fail(data.message);
      }
      // 授权失效,重定向地址获取微信授权code
      if (res.data.code === 401) {
        // Toast("登录超时");
        localStorage.setItem(Constants.TOKEN, '')
        /// jump2Login();
      }
      return Promise.resolve(data)
    }
  },
  e => {
    //  Toast.clear();
    // window.console.log(e)
    if (e.response) {
      // errorFn(e);
      return Promise.reject(e)
    } else {
      // vm.$toast.fail("系统错误");
    }
  }
)

// 导出请求方法
export default request
//
// // 谁要使用谁就加载 request 模块
// // import request from 'request.js'
// // request.xxx
// // request({
// //   method: ,
// //   url: ''
// // })
