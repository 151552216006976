import request from '@/utils/request'

// 添加字典类型
export const addSysDictionaryType = data => {
  return request({
    method: 'POST',
    url: '/api/SysDictionaryType/add',
    data
  })
}
// 编辑字典类型
export const editSysDictionaryType = data => {
  return request({
    method: 'PUT',
    url: '/api/SysDictionaryType/edit',
    data
  })
}
// 根据id查询字典类型
export const InquireSysDictionaryType = id => {
  return request({
    method: 'GET',
    url: `/api/SysDictionaryType/${id}`,
  })
}
// 获得字典栏目Tree列表
export const getSysDictionaryType = data => {
  return request({
    method: 'POST',
    url: '/api/SysDictionaryType/gettree',
    data
  })
}
// 根据字典id获取字典
export const getSysDictionary = id => {
  return request({
    method: 'GET',
    url: `/api/SysDictionary/${id}`,
  })
}
// 添加字典
export const addSysDictionary = data => {
  return request({
    method: 'POST',
    url: '/api/SysDictionary/add',
    data
  })
}
// 编辑字典
export const editSysDictionary = data => {
  return request({
    method: 'PUT',
    url: '/api/SysDictionary/edit',
    data
  })
}
// 修改字典启用/禁用
export const editStatusSysDictionary = data => {
  return request({
    method: 'PUT',
    url: '/api/SysDictionary/editStatus',
    data
  })
}
// 根据字典类型标识获取字典项
export const getDictionaryByTypeCode = params => {
  return request({
    method: 'GET',
    url: `/api/SysDictionary/GetDictionaryByTypeCode`,
    params
  })
}
// 查询字典列表
export const getQuery = params => {
  return request({
    method: 'GET',
    url: `/api/SysDictionary/query`,
    params
  })
}

// 删除字典列表
export const deleteSysDictionary = id => {
  return request({
    method: 'DELETE',
    url: `/api/SysDictionary/delete`,
    data:{
      id
    }
  })
}

