<template>
  <div class="article-container sf">
    <div slot="header" class="clearfix sf-breadcrumb">
      <!--面包屑-->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>案件管理</el-breadcrumb-item>
        <el-breadcrumb-item>已办案件</el-breadcrumb-item>
        <el-breadcrumb-item>案件详情</el-breadcrumb-item>
      </el-breadcrumb>
      <!--/面包屑-->
    </div>
    <el-card class="filter-card">

      <!--数据表格筛选-->
      <el-form ref="detailForm" :model="search" label-width="40px">
        <el-row :gutter="10">
          <el-col :span="7">
            <el-input placeholder="请输入姓名" v-model="search.Keyword" class="input-with-select">
              <el-button slot="append" type="warning" icon="el-icon-search" @click="loadPeopleBySearch()"></el-button>
            </el-input>
          </el-col>
          <el-col :span="9">
            <el-button type="primary" @click="toggle" plain>高级搜索</el-button>
          </el-col>
          <el-col :span="8" class="text-align-r">
            <el-button type="warning" icon="el-icon-plus" @click="addpc()">新增当事人</el-button>
            <el-dialog class="increaseForm" title="新增人员" append-to-body :visible.sync="dialogPeoVisible"
                       :close-on-click-modal="false">
              <people @dialogChanged="dialogChanged" :CaseId='search.CaseId'/>
            </el-dialog>
          </el-col>
        </el-row>
        <el-row class="sf-search-more" v-show="isShow">
          <el-col :span="6">
            <el-form-item label="当事人姓名" label-width="100px">
              <el-input placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="证件号码" label-width="80px">
              <el-input placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="当事人类型" label-width="100px">
              <el-select placeholder="当事人类型" v-model="search.type">
                <el-option label="当事人类型一" value="1"></el-option>
                <el-option label="当事人类型二" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="text-align: right;">
            <el-form-item>
              <el-button type="primary">搜索</el-button>
              <el-button type="primary" plain>清空</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!--/数据表格筛选-->

      <!--数据表格-->
      <el-table  ref="tableRef" :height="tableHeight"  :data="tableData" border style="width: 100%; margin: 20px 0">
        <el-table-column
          width="60"
          label="序号"
          type="index"
          :index="indexMethod">
        </el-table-column>
        <el-table-column prop="Name" align="center" label="当事人姓名" width="180">
        </el-table-column>
        <el-table-column prop="PersonType" align="center" label="当事人类型">
        </el-table-column>
        <el-table-column prop="CardType" align="center" label="身份类型">
        </el-table-column>
        <el-table-column prop="IDCard" align="center" label="证件号码" width="180">
        </el-table-column>
        <el-table-column prop="Phone" align="center" label="联系电话" width="120">
        </el-table-column>
        <el-table-column prop="InquiryStatus" align="center" label="询问状态" width="120">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.InquiryStatus === 0" type="info">待配置</el-tag>
            <el-tag v-if="scope.row.InquiryStatus === 1" type="">待准备</el-tag>
            <el-tag v-if="scope.row.InquiryStatus === 2" type="success">待创建会议室</el-tag>
            <el-tag v-if="scope.row.InquiryStatus === 3" type="">待询问</el-tag>
            <el-tag v-if="scope.row.InquiryStatus === 4" type="success">已询问</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="MeetingNum" align="center" label="被询问次数">
        </el-table-column>
        <el-table-column prop="CreateTime" align="center" label="创建时间" width="180">
        </el-table-column>
        <el-table-column prop="peoOperate" align="center" label="操作" width="300">
          <template slot-scope="scope">
            <div class="icon-box">
              <a @click="casedDetail" class="btn-view"><i class="el-icon-view"></i>询问详情</a>
              <!--  -->
              <a @click="inquiryBtn(scope.row)" class="btn-view" v-if="scope.row.InquiryStatus === 0"><i
                class="el-icon-setting"></i>配置询问事项</a>
              <a @click="handle(scope.row)" v-if="scope.row.InquiryStatus === 1" class="btn-view"><i
                class="el-icon-document-checked"></i>问前准备</a>
              <a @click="create(scope.row)" class="btn-view" v-if="scope.row.InquiryStatus === 2"><i
                class="el-icon-video-play"></i>待创建会议室</a>
              <a @click="process(scope.row)" class="btn-view" v-if="scope.row.InquiryStatus === 3"><i
                class="el-icon-chat-line-round"></i>开始询问</a>
              <a @click="dialogContinuedVisible = true" class="btn-view" v-if="scope.row.InquiryStatus === 4"><i
                class="el-icon-chat-dot-round"></i>继续询问</a>
              <a href="#" class="btn-view"><i class="el-icon-chat-line-round"></i>再次询问</a>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!--/数据表格-->
      <!--分页条-->
      <el-pagination
        class="pagination"
        background
        layout="total, sizes, prev, pager, next"
        :disabled="loading"
        @size-change="changePageSize"
        @current-change="changeCurrentPage"
        :current-page="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total">
      </el-pagination>
      <!--/分页条-->
    </el-card>
    <el-dialog title="确认提示" append-to-body :visible.sync="dialogContinuedVisible" width="30%" center>
      <i class="el-icon-success" style="font-size: 20px; color: #67c23a;"></i>
      <span>是否确定继续询问</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogContinuedVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogForm">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog class="increaseForm" title="配置询问事项" append-to-body :visible.sync="dialogInquiryVisible">
      <inquiry :dialogInquiryVisible="dialogInquiryVisible" :PersonId="PersonId"
               :InquiryStatus="tableData.InquiryStatus"
               @editChanged="inquiryDataChange"/>
    </el-dialog>
  </div>
</template>

<script>
import Inquiry from '@/views/case/components/inquiry'
import People from '@/views/case/components/people'
import { getPersonList, deletePeople } from '@/api/personConcerned'

export default {
  name: 'CasedDetail',
  components: {
    People,
    Inquiry
  },
  props: {},
  data () {
    return {
      tableData: [{
        xuHao: '1',
        peoName: '案件名称',
        peoType: '行政案件',
        peoIdType: '案件简述',
        peoId: '处理进度',
        peoPhone: '4天',
        peoStateTime: '操作',
        peoTime: '2'
      }],
      // 查询条件
      search: {
        page: 1,
        pageSize: 10,
        keyword: '',
        CaseId: 0,
        type: 0
      },
      // 分页信息
      pageInfo: {
        total: 0, // 总记录数，根据接口获取数据长度(注意：这里不能为空)
        pageSize: 10, // 默认每页数据量
        currentPage: 1
      },
      PersonId: 0,
      input1: '',
      isShow: false,
      dialogPeoVisible: false,
      dialogContinuedVisible: false,
      dialogInquiryVisible: false,
      caseData: [],
      tableHeight: 500
    }
  },
  computed: {},
  watch: {
    '$route' (to, from) {
      this.loadPeople(1)
    }
  },
  created () {
    this.loadPeople(1)
  },
  mounted () {
    this.$nextTick(function () {
      this.tableHeight = window.innerHeight - this.$refs.tableRef.$el.getBoundingClientRect().top - 137
      const that = this
      window.onresize = function () {
        that.tableHeight = window.innerHeight - that.$refs.tableRef.$el.getBoundingClientRect().top - 137
      }
    })
  },
  methods: {
    indexMethod (index) {
      return index + 1
    },
    loadPeopleBySearch () {
      this.search.page = 1
      this.loadPeople()
    },
    loadPeople (page = 1, tf = false) {
      // 展示加载中 loading
      this.loading = true
      this.search.CaseId = this.$route.query.CaseId
      console.log('search:', this.search)
      getPersonList(this.search).then(res => {
        console.log(res, 'res')
        this.tableData = []
        if (res.data) {
          this.tableData = res.data
          console.log('this.tableData', this.tableData)
          // 新增完毕
          if (tf) {
            this.search.page = res.totalPages
          }
        }
        // 不管有没有数据，分页都要随着变化
        this.pageInfo.currentPage = res.currentPage
        this.pageInfo.pageSize = res.pageSize
        this.pageInfo.total = res.total
        // 关闭加载中 loading
        this.loading = false
      })
    },
    changePageSize (pageSize) {
      console.log(`每页 ${pageSize} 条`)
      this.search.pageSize = pageSize // 改变每页显示的条数
      this.search.page = 1 // 注意：在改变每页显示的条数时，要将页码显示到第一页
      this.loadPeople() // 点击每页显示的条数时，显示第一页
    },
    changeCurrentPage (page) {
      console.log(`当前第 ${page} 页`)
      this.search.page = page // 改变默认的页数
      this.loadPeople()
    },
    dialogChanged (val) {
      console.log('IncreaseDataChange', val)
      this.dialogPeoVisible = val
      this.loadPeople(this.search.page)
    },
    // 配置询问事项
    inquiryBtn (val) {
      console.log('inquiryBtn', val)
      this.PersonId = val.PersonId
      this.dialogInquiryVisible = true
    },
    inquiryDataChange (param1) {
      console.log('inquiryDataChange', param1)
      this.dialogInquiryVisible = false// param1
      this.loadPeople()
    },
    // 待创建会议室
    create (val) {
      this.$router.push('/case/video?CaseId=' + val.CaseId + '&&PersonId=' + val.PersonId)
    },
    // 问前准备
    handle (val) {
      // console.log("handle",val)
      this.$router.push('/case/plan?CaseId=' + val.CaseId + '&&PersonId=' + val.PersonId)
    },
    // 开始询问
    process (val) {
      // console.log("process",val)
      this.$router.push('/case/process?CaseId=' + val.CaseId + '&&PersonId=' + val.PersonId)
    },
    getTable () {

    },
    addpc () {
      this.refresh()
      this.dialogPeoVisible = true
    },
    refresh () {
      this.refresh1 = false
      this.$nextTick(() => {
        this.refresh1 = true
      })
    },
    loadArticles (page = 1) {
    },
    // 高级搜索框开关
    toggle () {
      this.isShow = !this.isShow
    },
    casedDetail () {
      // this.$router.push('/cased/detail/inquiryDetail')
      this.$router.push({
        path: '/cased/detail/inquiryDetail',
        query: {
          CaseId: this.$route.query.CaseId
        }
      })
    },
    dialogForm () {
      this.dialogContinuedVisible = false
      this.dialogInquiryVisible = true
    }
  }
}
</script>

<style>
.filter-card {
  margin-bottom: 30px;
}

.list-table {
  margin-bottom: 20px;
}

.article-cover {
  width: 60px;
  background-size: cover;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

.increaseForm {
  text-align: center;
}

.el-select .el-input {
  width: 130px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.sf-breadcrumb {
  padding-bottom: 13px;
  border-bottom: #cbddff 1px solid;
  margin-bottom: 20px;
}

.sf-breadcrumb .el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  font-weight: normal !important;
}

.text-align-r {
  text-align: right;
}

.sf-search-more {
  border: #d8e3f8 1px solid;
  border-radius: 4px;
  padding: 20px 20px 0 20px;
  margin-top: 20px;
  background: #e7effd;
}
</style>
