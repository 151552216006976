<template>
  <div>
    <el-form ref="addForm" :model="increaseForm" label-width="80px" size="mini">
      <div class="tip">
        <span><i class="el-icon-s-opportunity" style="color: #E6A23C;"></i><i
          style="color: #E6A23C; font-style: normal;">友情提示：</i>请您根据此当事人的实际情况，选择对应的询问事项。</span>
      </div>
      <div class="mainbox">
        <div class="box" v-for="item in inquiryList" :key='item.TemplateId'
             :class="{changeStyle: arr.includes(item.TemplateId)}" @click="selectSealItem(item.TemplateId)">
          <a>
            <h1>{{ item.Name }}</h1>
          </a>
        </div>
      </div>
      <el-button type="primary" @click="inquirySaveBtn">保存</el-button>
    </el-form>
  </div>
</template>

<script>
import { queryTemplate } from '@/api/template'
import { addQuestionConfig,getList } from '@/api/case'

export default {
  name: 'Inquiry',
  components: {},
  props: {
    dialogInquiryVisible: {
      type: Boolean,
      default: true // 默认值
    },
    InquiryStatus: {
      type: Number,
      default: 0
    },
    PersonId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      changeSelectStyle: '',
      arr: [],
      increaseForm: {
        personId: 0,
        templateIds: []
      },
      // 绑定数据
      inquiryList: [
      ],
      search:{
        keyword: '',
        pageSize: 12,
        page: 1,
      }
    }
  },
  created () {
    this.loadTemplates()
  },
  methods: {
    loadTemplates () {
      console.log("this.PersonId",this.PersonId)
      queryTemplate(this.PersonId).then(res => {
        console.log('queryTemplate', res)
        this.inquiryList = res.data
      })
      getList(this.PersonId).then(res => {
        console.log('getList', res)
        // this.inquiryList = res.data
        let data=res
        this.arr=[]
        this.increaseForm.templateIds=[]
        for(let i in data){
          this.arr.push(data[i].TemplateId)
          this.increaseForm.templateIds.push(data[i].TemplateId)
        }
      })
    },
    // 选中样式切换
    selectSealItem (i) {
      console.log("selectSealItem",i)
      console.log("this.arr.indexOf(i)",this.arr.indexOf(i))
      if (this.arr.indexOf(i)!=-1) {
        this.arr = this.arr.filter(function (ele) {
          return ele !== i
        })
        let list = []
        for(let j of this.increaseForm.templateIds){
          if(j!=i){
            list.push(j)
          }
        }
        this.increaseForm.templateIds=list
      } else {
        this.arr.push(i)
        this.increaseForm.templateIds.push(i)
      }
      
    },
    inquirySaveBtn () {
      this.increaseForm.personId=this.PersonId
      console.log("this.increaseForm",this.increaseForm)
      if (this.PersonId) {
        const t = this
        addQuestionConfig(this.increaseForm).then((res) => {
          t.$message({
            message: '保存成功',
            type: 'success'
          })
          this.$emit('editChanged', this.increaseForm)
          this.loadTemplates()
        }).catch(res => {
          console.log(res)
        }) 
      }
    },

  }
}
</script>

<style>
.select-type {
  display: flex !important;
}

.tip {
  text-align: left;
  border: solid 1px #DCDFE6;
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
  margin-bottom: 20px;
}

.box a {
  height: 70px;
  width: 170px;
  border: solid 1px #DCDFE6;
  padding: 20px;
  text-align: left;
  margin-bottom: 20px;
  display: inline-block;
  text-decoration: none;
  color: #606266;
}

.box a:hover, .changeStyle {
  color: #409EFF;
}

.box a {
  cursor: pointer;
}

.box a h1 {
  font-size: 20px;
  margin: 0;
}

.box a span {
  margin-top: 20px;
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}

.box a span i {
  font-size: 20px;
}

.mainbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
