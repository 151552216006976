<template>
  <el-menu class="nav-menu el-menu-vertical-demo sf-side" :default-active="$route.path" text-color="#fff"
           active-text-color="#ff6400" router :collapse="isCollapse">
    <div class="iconfold">
      <i :class="{'el-icon-s-fold':isCollapse, 'el-icon-s-unfold':!isCollapse}"
         @click="isCollapse = !isCollapse"></i>
    </div>

    <template v-for="item in activeRoutes">
      <el-submenu v-if="item.children.length > 1" :key="item.path" :index="item.meta.title">
        <template slot="title">
          <i :class="item.meta.icon"></i>
          <span>{{item.meta.title}}</span>
        </template>
          <el-menu-item-group>
            <el-menu-item v-for="itemChild in item.children" :key="itemChild.path" :index="itemChild.path" style="padding-left: 50px;">{{itemChild.meta.title}}</el-menu-item>
          </el-menu-item-group>
      </el-submenu>
      <el-menu-item v-else v-for="itemChild in item.children" :key="itemChild.path" :index="itemChild.path">
        <i :class="item.meta.icon"></i>
        <span slot="title">{{itemChild.meta.title}}</span>
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script>
import { Constants } from '@constants'

export default {
  name: 'AppAside',
  components: {},
  props: ['is-collapse'],
  data () {
    return {
      activeRoutes:[]
      // activeRoutes : [
      //   {
      //     path: '/case',
      //     component: 'Layout',
      //     meta:{title:'案件管理',icon:'el-icon-tickets'},
      //     children: [
      //       {
      //         path: '/case/all',
      //         name: 'caseAll',
      //         component: 'case-all',
      //         meta:{title:'全部案件'}
      //       },
      //       {
      //         path: '/case',
      //         name: 'case',
      //         component: 'case',
      //         meta:{title:'待办案件'}
      //       },
      //       {
      //         path: '/cased',
      //         name: 'cased',
      //         component: 'cased',
      //         meta:{title:'已办案件'}
      //       },

      //     ]
      //   },
      //   {
      //     path: '/template',
      //     component: 'Layout',
      //     meta:{title:'文件模板',icon:'el-icon-document'},
      //     children: [
      //       {
      //         path: '/template/index',
      //         name: 'template',
      //         component: 'template',
      //         meta:{title:'文件模板'}
      //       }
      //     ]
      //   },
      //   {
      //     path: '/settings',
      //     component: 'Layout',
      //     meta:{title:'系统管理',icon:'el-icon-setting'},
      //     children: [
      //       {
      //         path: '/settings/users',
      //         name: 'users',
      //         component: 'users',
      //         meta:{title:'用户管理'}
      //       },
      //       {
      //         path: '/settings/roles',
      //         name: 'roles',
      //         component: 'roles',
      //         meta:{title:'角色管理'}
      //       },
      //       {
      //         path: '/settings/dictionary',
      //         name: 'dictionary',
      //         component: 'dictionary',
      //         meta:{title:'系统字典'}
      //       }
      //     ]
      //   }
      // ]
    }
  },
  computed: {},
  watch: {},
  created () {
    this.activeRoutes = JSON.parse(Constants.getSessionStorage('activeRoutes'))
  },
  mounted () {
  },
  methods: {}
}
</script>

<style scoped lang="less">
.nav-menu {
  .iconfont {
    margin-right: 10px;
    padding-left: 5px;
  }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 240px;
  min-height: 400px;
}
</style>
<style type="text/css">
.el-menu-item i {
  color: #deeaff !important;
}

.sf-side .iconfold {
  font-size: 26px;
  font-weight: normal;
  height: 46px;
  background-color: #2158c2;
  text-align: center;
  color: #deeaff;
  line-height: 46px;
}

.sf-side {
  background-image: url(../../../assets/sidebg.jpg) !important;
  background-position: bottom;
}

.sf-side .el-menu-item {
  font-size: 15px !important;
  letter-spacing: 1px;

}

.el-menu-item.is-active {
  border-right: #ff6400 2px solid;
}

.el-menu-item:focus,
.el-menu-item:hover {
  background-color: none;
  color: rgb(255, 100, 0);
}

.sf-side .el-menu-item-group__title {

  padding: 0 !important;
}

.sf-side .el-submenu__title i {
  color: #fff;
  font-size: 15px !important;
  letter-spacing: 1px;
}

.sf-side .el-submenu__title {
  font-size: 15px !important;
  letter-spacing: 1px;
}

.el-menu-item-group__title {
  padding: 0 !important;
}

.el-menu--popup-right-start {
  margin: 0 !important;
}

.el-menu--popup {
  background-color: #2158c2 !important;
  margin: 0 !important;
}

.el-menu--popup {
  padding: 0 !important;
}

.el-menu {
  background-color: transparent !important;
}

.el-tooltip__popper.is-dark {
  background-color: #1e4ca5 !important;
}

.el-menu-item:focus,
.el-menu-item:hover,
.el-submenu__title:hover {
  background-color: transparent !important;
  color: #ff6400 !important;
}

.popper__arrow {
  border-right-color: #1e4ca5 !important;
}
</style>
