// 文章相关请求模块
import request from '@/utils/request'
// 根据案件会议Id获取案件会议
export const getCaseMeeting = id => {
  return request({
    method: 'GET',
    url: `/api/CaseMeeting/${id}`,
  })
}

// 添加案件会议
export const addCaseMeeting = data => {
  return request({
    method: 'POST',
    url: '/api/CaseMeeting/add',
    data
  })
}

// 编辑案件会议
export const editCaseMeeting = data => {
  return request({
    method: 'PUT',
    url: '/api/CaseMeeting/edit',
    data
  })
}

//根据视频id获取视频的下载链接
export const getVodDownloadUl = vodId => {
  return request({
    method: 'GET',
    url: `/api/Vod/DownloadUrl/${vodId}`,
  })
}

//视频查询列表
export const vodQuery = data => {
  return request({
    method: 'POST',
    url: '/api/Vod/query',
    data
  })
}

