// 文章相关请求模块
import request from '@/utils/request'

// 获取材料文件列表
export const getMaterialList = params => {
  return request({
    method: 'GET',
    url: '/api/PersonConcernedMaterial/query',
    params
  })
}
