// 文章相关请求模块
import request from '@/utils/request'

// 新增案件
export const generateUrlLink = data => {
    return request({
      method: 'POST',
      url: '/wc/api/link/GenerateUrlLink?app_id=wxd996bc998907f160',
      data
    })
  }