<template>
  <el-form ref="editForm" :model="detailEditForm" label-width="90px" size="mini">
    <el-form-item label="当事人编号" prop="id" style="display: none">
      <el-input v-model="detailEditForm.id"></el-input>
    </el-form-item>
    <el-form-item label="当事人姓名" prop="name">
      <el-input v-model="detailEditForm.name" :disabled="InquiryStatus == 8"></el-input>
    </el-form-item>
    <el-form-item label="当事人类型" prop="personType">
      <el-select class="select-type" v-model="detailEditForm.personType" placeholder="请选择当事人类型" :disabled="InquiryStatus == 8">
        <el-option v-for="item in caseData" :key="item.Value" :label="item.Name" :value="item.Value"></el-option>
        <!-- <el-option label="行政案件" value="行政"></el-option>
        <el-option label="法律案件" value="法律"></el-option> -->
      </el-select>
    </el-form-item>
    <el-form-item label="身份类型" prop="cardType">
      <el-select class="select-type" v-model="detailEditForm.cardType" placeholder="请选择当事人类型" :disabled="InquiryStatus == 8">
        <el-option v-for="item in cardTypeList" :key="item.Value" :label="item.Name" :value="item.Value"></el-option>
        <!-- <el-option label="身份类型一" value="类型一"></el-option>
        <el-option label="身份类型二" value="类型二"></el-option> -->
      </el-select>
    </el-form-item>
    <el-form-item label="证件号码" prop="idCard">
      <el-input v-model="detailEditForm.idCard" :disabled="InquiryStatus == 8"></el-input>
    </el-form-item>
    <el-form-item label="联系电话" prop="phone">
      <el-input v-model="detailEditForm.phone" :disabled="InquiryStatus == 8"></el-input>
    </el-form-item>
    <el-form-item label="居住地址" prop="address">
      <el-input v-model="detailEditForm.address" :disabled="InquiryStatus == 8"></el-input>
    </el-form-item>
    <el-form-item label="备注" prop="remark">
      <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="detailEditForm.remark" :disabled="InquiryStatus == 8">
      </el-input>
    </el-form-item>
    <el-form-item label="证件照片">
      <div class="peoPic">
        <el-upload class="avatar-uploader"
                    :show-file-list="true" list-type="picture-card" 
                    :on-remove="handleRemove1" :http-request="uploadImage"
                    :limit="1" :disabled="InquiryStatus == 8" :file-list="photoPathList1">
            <!-- <img v-if="detailEditForm.photoPath" :src="('http://fw.zsyunsu.com/'+detailEditForm.photoPath)" class="avatar"> -->
            <i class="el-icon-plus"></i>
          <div slot="tip" class="el-upload__tip">证件照</div>
        </el-upload>
        <el-upload class="avatar-uploader"
                    :show-file-list="true" list-type="picture-card" 
                    :on-remove="handleRemove2" :http-request="uploadImageFront"
                    :limit="1" :disabled="InquiryStatus == 8" :file-list="photoPathList2">
          <i class="el-icon-plus"></i>
          <div slot="tip" class="el-upload__tip">身份证正面照</div>
        </el-upload>
        <el-upload class="avatar-uploader"
                    :show-file-list="true" list-type="picture-card" 
                    :on-remove="handleRemove3" :http-request="uploadImageBack"
                    :limit="1" :disabled="InquiryStatus == 8" :file-list="photoPathList3">
          <i  class="el-icon-plus"></i>
          <div slot="tip" class="el-upload__tip">身份证背面照</div>
        </el-upload>
      </div>
    </el-form-item>
    <el-button type="danger" @click="deleteBtn" v-if="InquiryStatus != 8">删除</el-button>
    <el-button type="primary" @click="editSaveBtn" v-if="InquiryStatus != 8">保存</el-button>
    <el-button type="primary" @click="closeBtn" v-if="InquiryStatus == 8">关闭</el-button>
  </el-form>
</template>

<script>
import { editPeople, getPeopleId,deletePeople } from '@/api/personConcerned'
import { upload } from '@/api/common'
import { getDictionaryByTypeCode } from '@/api/dictionary'

export default {
  name: 'edit',
  components: {},
  props: {
    dialogDetailEditVisible: {
      type: Boolean,
      default: true // 默认值
    },
    PersonId: {
      type: Number,
      default: 123
    },
    InquiryStatus:{
      type: Number,
      default : -1
    }
  },
  data () {
    return {
      detailEditForm: {
        id: 0,
        name: '',
        personType: '',
        cardType: '',
        idCard: '',
        phone: '',
        address: '',
        remark: '',
        photoPath: '',
        idCardFrontPhotoPath: '',
        idCardBackPhotoPath: ''
      },
      dialogImageUrl: '',
      dialogVisible: false,
      disable: false,
      imgurl: [],
      caseData: [],
      cardTypeList: [],
      photoPathList1:[],
      photoPathList2:[],
      photoPathList3:[],
    }
  },
  created () {
    this.initialization()
  },
  mounted () {
    this.loadEditCase()
  },
  methods: {
    initialization () {
      getDictionaryByTypeCode({code:'PERSONTYPE',status:0}).then(res => {
        this.caseData = res
      })
      getDictionaryByTypeCode({code:'CARDTYPE',status:0}).then(res => {
        this.cardTypeList = res
      })
    },
    loadEditCase () {
      getPeopleId(this.PersonId).then(res => {
        this.detailEditForm.id = res.PersonId
        this.detailEditForm.name = res.Name
        this.detailEditForm.personType = res.PersonType
        this.detailEditForm.cardType = res.CardType
        this.detailEditForm.idCard = res.IDCard
        this.detailEditForm.phone = res.Phone
        this.detailEditForm.address = res.Address
        this.detailEditForm.remark = res.Remark
        this.detailEditForm.photoPath = res.PhotoPath
        this.detailEditForm.idCardFrontPhotoPath = res.IDCardFrontPhotoPath
        this.detailEditForm.idCardBackPhotoPath = res.IDCardBackPhotoPath
        console.log(this.detailEditForm)
        if(this.detailEditForm.photoPath != ''){
          this.photoPathList1.push({
            name:'1',
            url:'http://fw.zsyunsu.com/'+this.detailEditForm.photoPath
          })
        }
        if(this.detailEditForm.idCardFrontPhotoPath != ''){
          this.photoPathList2.push({
            name:'2',
            url:'http://fw.zsyunsu.com/'+this.detailEditForm.idCardFrontPhotoPath
          })
        }
        if(this.detailEditForm.idCardBackPhotoPath != ''){
          this.photoPathList3.push({
          name:'3',
          url:'http://fw.zsyunsu.com/'+this.detailEditForm.idCardBackPhotoPath
          })
        }
        
      })

    },
    inquiryEditSaveBtn () {
      this.$message({
        message: '恭喜你，保存成功！',
        type: 'success'
      })
    },
    handleRemove1 (file) {
      this.detailEditForm.photoPath = ''
    },
    handleRemove2 (file) {
     this.detailEditForm.idCardFrontPhotoPath = ''
    },
    handleRemove3 (file) {
      this.detailEditForm.idCardBackPhotoPath = ''
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleDownload (file) {
      console.log(file)
    },
    editSaveBtn () {
      this.$refs.editForm.validate(valid => {
        if (!valid) {
          return
        }
        const PersonId = this.PersonId
        console.log(PersonId, '999')
        if (PersonId) {
          editPeople(PersonId, this.detailEditForm).then(res => {
            console.log(res, '111')
            this.detailEditForm = res.data
            this.$message({
              message: '保存成功',
              type: 'success'
            })
          })
          this.$emit('editChanged', false)
          this.loadEditCase()
        }
      })
    },
    deleteBtn(){
      this.$confirm('此操作将永久删除该当事人, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deletePeople(this.PersonId).then((res) => {
          this.$message({
              message: '删除成功',
              type: 'success'
            })
           this.$emit('editChanged', false)
        })
      })
    },
    closeBtn () {
      this.$emit('editChanged', false)
    },
    // 文件提交
    uploadImage (param) {
      const formData = new FormData()
      console.log(param, '1345')
      formData.append('file', param.file) // 提交给后台的文件
      upload(formData).then((res) => {
        console.log(res, '121212')
        if (res.success) {
          this.detailEditForm.photoPath = res.data
          console.log(this.detailEditForm)
        }
      })
    },
    // 文件提交
    uploadImageFront (param) {
      const formData = new FormData()
      console.log(param, '1345')
      formData.append('file', param.file) // 提交给后台的文件
      upload(formData).then((res) => {
        console.log(res, '121212')
        if (res.success) {
          this.detailEditForm.idCardFrontPhotoPath = res.data
          console.log(this.detailEditForm)
        }
      })
    },
    // 文件提交
    uploadImageBack (param) {
      const formData = new FormData()
      console.log(param, '1345')
      formData.append('file', param.file) // 提交给后台的文件
      upload(formData).then((res) => {
        console.log(res, '121212')
        if (res.success) {
          this.detailEditForm.idCardBackPhotoPath = res.data
          console.log(this.detailEditForm)
        }
      })
    }
  }
}
</script>

<style scoped>
.select-type {
  display: flex !important;
}
.peoPic {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.img-box {
  width: 148px;
  height: 148px;
  position: absolute;
  transform: translateX(-59.5%);
}
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 148px;
    height: 148px;
    line-height: 148px;
    text-align: center;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
  }
  .avatar {
    width: 148px;
    height: 148px;
    display: block;
  }
</style>
