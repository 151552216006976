<template>
  <div class="article-container sf">
    <div slot="header" class="clearfix sf-breadcrumb">
      <!--面包屑-->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>案件管理</el-breadcrumb-item>
        <el-breadcrumb-item>待办案件</el-breadcrumb-item>
        <el-breadcrumb-item>案件详情</el-breadcrumb-item>
        <el-breadcrumb-item>新增当事人</el-breadcrumb-item>
      </el-breadcrumb>
      <!--/面包屑-->
    </div>
    <el-card class="filter-card">

      <!--数据表格筛选-->
      <el-form ref="form" :model="form" label-width="40px">
        <el-row :gutter="10">
          <el-col :span="7">
            <el-input placeholder="请输入姓名" v-model="input1" class="input-with-select">
              <el-button slot="append" type="warning" icon="el-icon-search"></el-button>
            </el-input>
          </el-col>
          <el-col :span="9">
            <el-button type="primary" @click="toggle" plain>高级搜索</el-button>
          </el-col>
          <el-col :span="8" class="text-align-r">
            <el-button type="warning" icon="el-icon-plus" @click="dialogPeoVisible = true">新增当事人</el-button>
            <el-dialog class="increaseForm" title="新增人员" append-to-body :visible.sync="dialogPeoVisible"
                       :close-on-click-modal="false">
              <people/>
            </el-dialog>
          </el-col>
        </el-row>
        <el-row class="sf-search-more" v-show="isShow">
          <el-col :span="6">
            <el-form-item label="当事人姓名" label-width="100px">
              <el-input placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="证件号码" label-width="80px">
              <el-input placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="当事人类型" label-width="100px">
              <el-select placeholder="当事人类型">
                <el-option label="当事人类型一" value="当事人类型一"></el-option>
                <el-option label="当事人类型二" value="当事人类型二"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="text-align: right;">
            <el-form-item>
              <el-button type="primary">搜索</el-button>
              <el-button type="primary" plain>清空</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!--/数据表格筛选-->

      <!--数据表格-->
      <el-table :data="tableData" border v-loading="loading" style="width: 100%; margin: 20px 0">
        <el-table-column prop="CaseId" v-model="CaseId" align="center" label="序号" width="60">
          <template slot-scope="scope">
            <span v-text="getIndex(scope.$index)"> </span>
          </template>
        </el-table-column>
        <el-table-column prop="Name" v-model="Name" align="center" label="当事人姓名" width="180">
        </el-table-column>
        <el-table-column prop="PersonType" v-model="PersonType" align="center" label="当事人类型">
        </el-table-column>
        <el-table-column prop="CardType" v-model="CardType" align="center" label="身份类型">
        </el-table-column>
        <el-table-column prop="IDCard" v-model="IDCard" align="center" label="证件号码">
        </el-table-column>
        <el-table-column prop="Phone" v-model="Phone" align="center" label="联系电话">
        </el-table-column>
        <el-table-column prop="InquiryStatus" v-model="InquiryStatus" align="center" label="询问状态">
          <el-tag>待配置</el-tag>
        </el-table-column>
        <el-table-column prop="ModifyTime" v-model="ModifyTime" align="center" label="被询问次数">
        </el-table-column>
        <el-table-column prop="CreateTime" v-model="CreateTime" align="center" label="创建时间" width="120">
        </el-table-column>
        <el-table-column prop="peoOperate" align="center" label="操作" width="280">
          <div class="icon-box">
            <a @click="handle" class="btn-view"><i class="el-icon-document-checked"></i>问前准备</a>
            <a class="btn-view"><i class="el-icon-chat-line-round"></i>开始询问</a>
            <a @click="dialogDetailIncreaseEditVisible = true" class="btn-edit"><i class="el-icon-edit">编辑</i></a>
            <a @click="deleteBtn" class="btn-delete"><i class="el-icon-delete">删除</i></a>
          </div>
        </el-table-column>
      </el-table>
      <!--/数据表格-->
      <!--分页条-->
      <el-pagination
        class="pagination"
        background
        layout="total, sizes, prev, pager, next"
        :disabled="loading"
        @size-change="changePageSize"
        @current-change="changeCurrentPage"
        :current-page="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total">
      </el-pagination>
      <!--/分页条-->
    </el-card>
    <el-dialog class="increaseForm" title="编辑" append-to-body :visible.sync="dialogDetailIncreaseEditVisible"
               :close-on-click-modal="false">
      <detail-increase-edit/>
    </el-dialog>
  </div>
</template>

<script>
import People from './components/people'
import DetailIncreaseEdit from './components/detail-increase-edit'
import { getPersonList, deletePeople } from '@/api/personConcerned'

export default {
  name: 'detail-increase',
  components: {
    People,
    DetailIncreaseEdit
  },
  props: {},
  data () {
    return {
      tableData: [{
        CaseId: 1,
        Name: '',
        PersonType: '',
        CardType: '',
        IDCard: '',
        Phone: '',
        InquiryStatus: '',
        ModifyTime: '',
        Remark: '',
        CreateTime: '',
        PersonId: ''
      }],
      input1: '',
      isShow: false,
      dialogPeoVisible: false,
      dialogDetailIncreaseEditVisible: false,
      // 查询条件
      search: {
        page: 1,
        pageSize: 10,
        keyword: ''
      },
      // 分页信息
      pageInfo: {
        total: 0, // 总记录数，根据接口获取数据长度(注意：这里不能为空)
        pageSize: 10, // 默认每页数据量
        currentPage: 1
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.loadPeople(1)
    this.getID()
  },
  mounted () {
    this.getID()
  },
  methods: {
    loadPeople (page = 1) {
      // 展示加载中 loading
      this.loading = true
      getPersonList(this.search).then(res => {
        this.pageInfo.currentPage = res.currentPage
        this.pageInfo.pageSize = res.pageSize
        this.pageInfo.total = res.total
        this.tableData = res.data
        // 关闭加载中 loading
        this.loading = false
        console.log(res, '66666')
      })
    },
    // 高级搜索框开关
    toggle () {
      this.isShow = !this.isShow
    },
    handle () {
      this.$router.push('/case/plan')
    },
    changePageSize (pageSize) {
      console.log(`每页 ${pageSize} 条`)
      this.search.pageSize = pageSize // 改变每页显示的条数
      this.search.page = 1 // 注意：在改变每页显示的条数时，要将页码显示到第一页
      this.loadPeople() // 点击每页显示的条数时，显示第一页
    },
    changeCurrentPage (page) {
      console.log(`当前第 ${page} 页`)
      this.search.page = page // 改变默认的页数
      this.loadPeople()
    },
    deleteBtn () {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 路由传参
    getID () {
      this.tableData.CaseId = this.$route.query.CaseId
      console.log(this.tableData.CaseId, '444')
    }
  }
}
</script>

<style>
.filter-card {
  margin-bottom: 30px;
}

.list-table {
  margin-bottom: 20px;
}

.article-cover {
  width: 60px;
  background-size: cover;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

.increaseForm {
  text-align: center;
}

.el-select .el-input {
  width: 130px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.sf-breadcrumb {
  padding-bottom: 13px;
  border-bottom: #cbddff 1px solid;
  margin-bottom: 20px;
}

.sf-breadcrumb .el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  font-weight: normal !important;
}

.text-align-r {
  text-align: right;
}

.sf-search-more {
  border: #d8e3f8 1px solid;
  border-radius: 4px;
  padding: 20px 20px 0 20px;
  margin-top: 20px;
  background: #e7effd;
}
</style>
