import axios from 'axios'
// 文章相关请求模块
import request from '@/utils/request'

// 签署流程查询
export const signSearch = flowId => {
  return request({
    method: 'GET',
    url: `/api/PersonConcernedSign/${flowId}`,
  })
}

// 签署流程创建
export const signCreate = data => {
  return request({
    method: 'POST',
    url: '/api/PersonConcernedSign/create',
    data
  })
}
// 流程开始
export const signStart = data => {
  return request({
    method: 'PUT',
    url: '/api/PersonConcernedSign/start',
    data
  })
}

// 查询
export const signQuery = params => {
  return request({
    method: 'GET',
    url: '/api/PersonConcernedSign/query',
    params
  })
}

// 流程结束
export const signEnd = data => {
  return request({
    method: 'PUT',
    url: '/api/PersonConcernedSign/end',
    data
  })
}

// 流程撤销
export const signRevoke = data => {
  return request({
    method: 'PUT',
    url: '/api/PersonConcernedSign/revoke',
    data
  })
}

// 流程文件上传（前台跳过下载上传，后台统一处理）
export const signUpload = data => {
  return request({
    method: 'POST',
    url: '/api/PersonConcernedSign/upload',
    data
  })
}

