// 用户相关请求模块
import request from '@/utils/request'
import {Constants} from "@constants"

// 修改用户头像
export const updateUserPhoto = data => {
  return request({
    method: 'PATCH',
    url: '',
    data
  })
}

// 修改用户基本信息
export const updateUserProfile = data => {
  return request({
    method: 'PATCH',
    url: '',
    data
  });
}

/**
 * 获取当前登录用户信息,用于首页展示
 * @returns 
 */
export const getCurrentUserInfo = () => {
  return request({
    method: 'POST',
    url: '/api/UserInfo/currentUserInfo'
  });
}

/**
 * 用户登录
 * @param {*} data 
 * @returns 
 */
export const login = data => {
  return request({
    method: 'POST',
    url: '/api/User/login',
    data
  });
}

export const smsLogin = data => {
  return request({
    method: 'POST',
    url: '/api/User/PhoneLogin',
    data
  });
}

/**
 * 获取图片验证码url
 * @param {*} w 
 * @param {*} h 
 * @returns 
 */
export const validateCodeImage = (w,h)=>{
  const num = Math.ceil(Math.random() * 10); // 生成一个随机数（防止缓存）
  return request({
    method: 'GET',
    url: `/api/User/validatecode?${num}`
  });
}

/**
 * 当前登录用户修改密码
 * @param {*} data 
 * @returns 
 */
 export const modifyPassword = data => {
  return request({
    method: 'POST',
    url: '/api/UserInfo/modifyPassword',
    data
  });
}

/**
 * 获取手机验证码
 * @returns 
 */
 export const validateCodeSms = data=>{
  const num = Math.ceil(Math.random() * 10); // 生成一个随机数（防止缓存）
  return request({
    method: 'POST',
    url: `/api/User/SmsValidateCode?${num}`,
    data
  });
}