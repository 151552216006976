export const Constants = {
  TOKEN: 'token',
  set(key, val) {
    Constants[key] = val;
    return Constants[key];
  },
  get(key) {
    return Constants[key];
  },
  setLocalStorage(key, content, is_object) {
    is_object = is_object || false;
    is_object ? localStorage.setItem(key, JSON.stringify(content)) : localStorage.setItem(key, content);
  },
  getLocalStorage(key, is_object) {
    is_object = is_object || false;
    return is_object ? JSON.parse(localStorage.getItem(key)) : localStorage.getItem(key);
  },
  removeLocalStorage(key) {
    localStorage.removeItem(key);
  },
  setSessionStorage(key, content, is_object) {
    is_object = is_object || false;
    is_object ? sessionStorage.setItem(key, JSON.stringify(content)) : sessionStorage.setItem(key, content);
  },
  getSessionStorage(key, is_object) {
    is_object = is_object || false;
    return is_object ? JSON.parse(sessionStorage.getItem(key)) : sessionStorage.getItem(key);
  },
  removeSessionStorage(key) {
    sessionStorage.removeItem(key);
  }
}
