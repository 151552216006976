<template>
	<el-dropdown>
		<div class="avatar-wrap">
			<img class="avatar" :src="userInfo.HeadImage" alt="" width="40px" height="40px"/>
			<span>欢迎您，</span>
			<span>{{ userInfo.Name }}</span>
			<i class="el-icon-arrow-down el-icon--right"></i>
		</div>
		<el-dropdown-menu slot="dropdown">
			<div class="sf-userc">
				<p class="title">个人中心</p>
				<div class="pic"><img class="avatar" :src="('http://fw.zsyunsu.com/'+userInfo.HeadImage)" alt="" width="40px"/></div>
				<p><i class="el-icon-user"></i> {{ userInfo.UserName }}</p>
				<p><i class="el-icon-user"></i> {{ userInfo.Name }}</p>
				<p><i class="el-icon-phone-outline"></i> {{ userInfo.Phone }}</p>
			</div>
			<el-dropdown-item @click.native="outerVisible = true">修改密码</el-dropdown-item>
			<div class="sf-userb">
				<el-dropdown-item @click.native="home"><i class="el-icon-back"></i> 返回首页</el-dropdown-item>
				<el-dropdown-item @click.native="onLogout"><i class="el-icon-switch-button"></i> 退出平台</el-dropdown-item>
			</div>
		</el-dropdown-menu>
		<!-- 修改密码form -->
		<el-dialog title="修改密码" :visible.sync="outerVisible" :close-on-click-modal="false" style="width: 1250px; margin: 0 auto"
			:append-to-body="true">
			<el-form :rules="rules" :model="pwdList" style="width: 550px" status-icon ref="pwdList">
				<el-form-item prop="oldPassword" label="原密码" label-width="100px">
					<el-input v-model="pwdList.oldPassword" placeholder="请输入原密码" show-password />
				</el-form-item>
				<el-form-item prop="newPassword" label="新密码" label-width="100px">
					<el-input v-model="pwdList.newPassword" placeholder="新密码（5-12大小写字母、数字）" show-password />
				</el-form-item>
				<el-form-item prop="newPassword2" label="确认密码" label-width="100px">
					<el-input v-model="pwdList.newPassword2" placeholder="确认密码（5-12大小写字母、数字）" show-password />
				</el-form-item>
			</el-form>
			<el-dialog width="30%" title="修改成功" :visible.sync="rebuldDialogVisible" append-to-body>
				<span>密码修改成功！</span>
				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="rebuldDialogVisible = false">确定</el-button>
				</span>
			</el-dialog>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm('pwdList')">取 消</el-button>
				<el-button type="primary" @click="onModifyPassword('pwdList')">确定修改</el-button>
			</div>
		</el-dialog>
		<!-- /修改密码form -->
	</el-dropdown>
</template>

<script>
	import {
		Constants
	} from "@constants";
	import {
		modifyPassword,
		getCurrentUserInfo
	} from "@/api/user";
	import globalBus from "@/utils/global-bus";

	const pwdListDefault = {
		oldPassword: null,
		newPassword: null,
		newPassword2: null,
	};
	export default {
		name: "sfUser",
		props: {},
		data() {
			var checkPwd = (rule, value, callback) => {
				if (value !== this.pwdList.newPassword) {
					callback(new Error("密码不一致"));
				} else {
					callback();
				}
			};
			return {
				userInfo: {}, // 当前登录用户信息
				isCollapse: false, // 侧边菜单栏展开状态

				// dialogFormVisible: false,

				rebuldDialogVisible: false,
				pwdList: Object.assign({}, pwdListDefault),
				rules: {
					oldPassword: [{
						required: true,
						message: "请输入旧密码",
						trigger: "blur",
					}, ],
					newPassword: [{
						required: true,
						message: "请输入新密码",
						trigger: "blur",
					}, ],
					newPassword2: [{
							required: true,
							message: "请再次输入新密码",
							trigger: "blur",
						},
						{
							validator: checkPwd,
							trigger: "blur",
						},
					],
				},
				outerVisible: false,
			};
		},
		computed: {},
		watch: {},
		created() {
			this.loadCurrentUserInfo();
		},
		mounted() {},
		methods: {
			home () {
			  this.$router.push('/home')
			},
			resetForm(pwdList) {
				this.$refs[pwdList].resetFields();
			},
			loadCurrentUserInfo() {
				let currentUserInfo = Constants.getSessionStorage(Constants.get("UserInfo"), true);
				console.log('currentUserInfo',currentUserInfo);
				//判断SessionStorage是否存在当前用户信息
				if (currentUserInfo == null) {
					getCurrentUserInfo().then(res => {
						if (res.success) {
							currentUserInfo = res.data;
							//设置登录账号信息到SessionStorage
							Constants.setSessionStorage(Constants.set("UserInfo", "UserInfo"), currentUserInfo,
								true);
							this.userInfo = currentUserInfo;
						}
					});
				} else {
					this.userInfo = currentUserInfo;
				}
			},
			onModifyPassword() {
				let t = this;
				this.$refs["pwdList"].validate((valid) => {
					if (valid) {
						modifyPassword(this.pwdList)
							.then((res) => {
								if (res.success) {
									this.rebuldDialogVisible = true;
								} else {
									t.$message({
										type: "info",
										message: "res.message",
									});
									console.log(res);
								}
							})
							.catch((res) => {
								console.log(res);
							});
					} else {
						console.log("验证失败");
					}
				});
			},
			//退出，移除存储的Token，调回登录页面
			onLogout() {
				this.$confirm("确认退出吗?", "退出提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						Constants.removeLocalStorage(Constants.TOKEN);
						this.$router.push({
							name: "home"
						});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消退出",
						});
					});
			},
		},
	};
</script>

<style>
	.sf-userc{
		width: 300px;
		/* background-color: #eee; */
	}
	.sf-userc .title{
		text-align: center;
		border-bottom: #ff6400 2px solid;
		padding: 0 0 10px 0;
		margin-top: 0;
		font-weight: bold;
		color: #ff6400;
		font-size: 16px;
	}
	.sf-userc .pic{
		float: left; 
		margin: 0 15px;
		padding: 2px;
	}
	.sf-userc .pic img{
		width: 40px !important;
		height: 40px !important;
		border-radius: 50%;
		border: #ddd 1px solid;
		padding: 5px;
	}
	.sf-userb{
		float: left;
		width: 100%;
		border-top: #b6cfff 1px solid;
		margin-bottom: -10px;
		background-color: #e4edff;
	}
	
	.sf-userb > .el-dropdown-menu__item{
		float: left;
		width: 50%;
		text-align: center;
		padding: 5px 0;
		font-weight: bold;
	}
	
</style>