<template>
  <div class="article-container sf">
    <div slot="header" class="clearfix sf-breadcrumb">
      <!--面包屑-->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>文件模板</el-breadcrumb-item>
        <el-breadcrumb-item>案件详情</el-breadcrumb-item>
        <el-breadcrumb-item>视频接入</el-breadcrumb-item>
      </el-breadcrumb>
      <!--/面包屑-->
    </div>
    <el-card class="filter-card">

      <!--数据表格筛选-->
      <el-form class="btn-top" ref="form" :model="form" label-width="40px">
        <div>
          <!-- <el-button type="primary" @click="videoToUrl">视频接入</el-button> -->
        </div>
        <div style="float:right">
          <el-button class="el-icon-back" @click="back">返回</el-button>
        </div>
      </el-form>
      <!--/数据表格筛选-->

      <div class="template-header">
        <h2>视频接入</h2>
      </div>
      <div class="template-body">
        <div class="main-box">
          <div class="box2">
            <span class="title">会议室信息</span>
            <div class="box2-input" :class="{showinput:isshow}">
              <div class="dleft" :class="{boxleft:isshow}" v-if="isshow">
                <div class="el-icon-user" ></div>
                <div class="meetinginfo" >
                  <div>会议室号：</div><div>{{meetinginfo.MeetingNumber}}</div>
                  <div>密码：</div><div>{{meetinginfo.Password}}</div>
                  <div>控制密码：</div><div>{{meetinginfo.ControlPassword}}</div>
                </div>
              </div>
              <div class="dright" :class="{boxright:isshow}">
                <el-form ref="form" label-width="100px" size="mini">
                  <el-form-item label="会议室名称">
                    <el-input v-model="meetingName"></el-input>
                  </el-form-item>
                  <!-- <el-form-item label="会议时间">
                  <el-date-picker
      v-model="timeArr"
      type="daterange"
      align="right"
      unlink-panels
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      :picker-options="pickerOptions">
    </el-date-picker>
    </el-form-item> -->
                  <el-form-item label="会议时间">
                    <el-date-picker
                      v-model="starttime"
                      type="datetime"
                      value-format="timestamp"
                      placeholder="会议时间" @change = "startTimeClick">
                    </el-date-picker>
                  </el-form-item>
                  <!-- <el-form-item label="结束时间">
                    <el-date-picker
                      v-model="endtime"
                      type="datetime"
                      value-format="timestamp"
                      placeholder="结束时间" @change = "endTimeClick">
                    </el-date-picker>
                  </el-form-item> -->
                </el-form>
                <el-button type="primary" @click="save">保存</el-button>
              </div>
            </div>

          </div>
        </div>
      </div>

      <!--按钮-->
      <div class="btn-bottom">
        <div>
          <span style="color:red;margin-right: 20px;">提示：问询时请打开小鱼会议客户端，输入会议号和密码进入会议。</span>
          <el-button type="warning" @click="nextButton">开始询问</el-button>
        </div>
      </div>
      <!--/按钮-->
      <el-dialog class="increaseForm" title="编辑" append-to-body :visible.sync="dialogVideoEditVisible"
                 :close-on-click-modal="false">
        <video-edit/>
      </el-dialog>
    </el-card>

  </div>
</template>

<script>
import moment from "moment"
import { getCaseId } from '@/api/case'
import { getPersonMeeting, addPersonMeeting, editPersonMeeting } from '@/api/personMeeting'

export default {
  name: 'Template',
  props: {},
  data () {
    return {
      // 时间
      timeArr: '',
      starttime: '',
      endtime: '',
      meetinginfo: {
        CaseId: 0,
        PersonId:0,
        MeetingNumber: 0,
        ControlPassword: 0,
        Password: 0
      },
      isshow: false,
      meetingName: '',
      flag: false,
      input1: '',
      input2: '',
      input3: '',
      select: '',
      dialogFormVisible: false,
      dialogVideoEditVisible: false
    }
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
    this.initialization()
  },
  methods: {
    videoToUrl () {
      if (this.meetinginfo.MeetingNumber && this.meetinginfo.Password && this.meetingName) {
        window.open(`http://localhost:8081/#/login?meeting=${this.meetinginfo.MeetingNumber}&name=${this.meetingName}&password=${this.meetinginfo.Password}`)
      }
    },
    initialization () {
      this.get()
    },
    save () {
      if (this.isshow) {
        this.edit()
      } else {
        this.add()
      }
    },
    get () {
      getPersonMeeting(this.$route.query.CaseId,this.$route.query.PersonId).then(res => {
        console.log('getPersonMeeting', res)
        if (res) {
          this.meetinginfo.CaseId = res.CaseId
          this.meetinginfo.PersonId = res.PersonId
          this.meetinginfo.MeetingNumber = res.MeetingNumber
          this.meetinginfo.ControlPassword = res.ControlPassword
          this.meetinginfo.Password = res.Password
          this.meetingName = res.MeetingName
          this.starttime = Date.parse(res.MeetingTime)
          this.oldStarttime = Date.parse(res.MeetingTime)
          this.endtime = res.EndTime
          this.isshow = true
        }else{
          getCaseId(this.$route.query.CaseId).then(res => {
            console.log('getCaseId', res)
            this.meetingName = res.Name
          })
        }
      })
    },
    add () {
      if(this.starttime == ''){
        this.$message({
          type: 'info',
          message: '开始时间不能为空'
        })
        return
      }
      console.log(this.starttime)
      let meetingTime = moment(this.starttime).format("YYYY-MM-DD HH:mm:ss")
      const search = {
        meetingName: this.meetingName,
        caseId: this.$route.query.CaseId - 0,
        personId: this.$route.query.PersonId - 0,
        autoRecord:true,
        meetingTime: meetingTime,
        effectiveHour:1,
        day:2
      }
      addPersonMeeting(search).then(res => {
        console.log('addCaseMeeting', res)
        if (res) {
          this.isshow = true
          this.get()
        }
      })
    },
    edit () {
      let meetingTime = moment(this.starttime).format("YYYY-MM-DD HH:mm:ss")
      const search = {
        caseId: this.meetinginfo.CaseId,
        meetingName: this.meetingName,
        personId: this.meetinginfo.PersonId,
        autoRecord: true,
        meetingTime: meetingTime,
        day: 2
      }
      editPersonMeeting(search).then(res => {
        this.$message({
          type: 'success',
          message: '保存成功'
        })
        this.get()
      })
    },
    back () {
      this.$router.push('/case/detail?CaseId=' + this.$route.query.CaseId + '&&PersonId=' + this.$route.query.PersonId)
    },
    loadArticles (page = 1) {
    },
    nextButton () {
      if(this.isshow){
        this.$router.push('/case/process?CaseId=' + this.$route.query.CaseId + '&&PersonId=' + this.$route.query.PersonId)
      }else{
        this.$message({
          type: 'info',
          message: '请先创建会议室'
        })
      }
    },
    deleteBtn () {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    startTimeClick(e){
      if(this.oldStarttime != '' && e<this.oldStarttime){
        this.starttime = this.oldStarttime
        this.$message({
          type: 'info',
          message: '所选择的会议时间不能早于之前选择的会议时间!'
        })
      }
    },
    endTimeClick(e){
      console.log(this.starttime)
      console.log(e)
      if(e<=this.starttime){
        this.endtime = ''
        this.$message({
          type: 'info',
          message: '所选择的结束时间必须大于开始时间!'
        })
      }
    }
  }
}
</script>

<style scoped>
.filter-card {
  margin-bottom: 30px;
}

.list-table {
  margin-bottom: 20px;
}

.article-cover {
  width: 60px;
  background-size: cover;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

.increaseForm {
  text-align: center;
}

.el-select .el-input {
  width: 130px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.sf-breadcrumb {
  padding-bottom: 13px;
  border-bottom: #cbddff 1px solid;
  margin-bottom: 20px;
}

.sf-breadcrumb .el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  font-weight: normal !important;
}

.text-align-r {
  text-align: right;
}

.sf-search-more {
  border: #d8e3f8 1px solid;
  border-radius: 4px;
  padding: 20px 20px 0 20px;
  margin-top: 20px;
  background: #e7effd;
}

.sf-template {
  display: flex;
  border: #dcdfe6 1px solid;
  margin: 20px 0;
  padding: 10px;
  overflow-y: scroll;
}

.sf-template ul {
  width: 100%;
  height: 330px;
  padding: 0;
  margin: 0;
}

.sf-template ul li {
  box-sizing: border-box;
  float: left;
  display: flex;
  width: calc(20% - 28px);
  height: 270px;
  margin: 14px;
  border: #e7e9f0 1px solid;
  box-shadow: 3px 3px 3px #eee;
  background-color: #f4f8ff;
}

.sf-template ul li a {
  width: 100%;
  padding: 20px;
  font-size: 24px;
  text-align: center;
  line-height: 38px;
  font-family: 宋体;
  font-weight: bold;
}

.template-header {
  text-align: center;
}

.template-body {
  border: solid 1px #EBEEF5;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.box1, .box2 {
  border: solid 1px #EBEEF5;
  text-align: center;
}

.dright{
  width: 320px;
}

.boxleft{
  width: 60%;
  display: grid;
  align-items: center;
  justify-items: start;
  grid-template-columns: 30% 70%;
}
.boxright{

}

.meetinginfo{
  display: grid;
  align-items: center;
  justify-items: start;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto 1fr;
}

.main-box {
  width: 100%;
}

.box1 span, .box2 span {
  height: 30px;
  line-height: 30px;
  background: #eeeeee;
  width: 100%;
  display: inline-flex;
  justify-content: center;
}

.btn-bottom {
  display: flex;
  flex-direction: row-reverse;
}

.box2-input {
  width: 100%;
  margin: 0 auto;
  padding: 30px;
  display: grid;
  align-items: center;
  justify-items: center;
}
.showinput {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 40% 60%;
}

.btn-top {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 1920px) {
  .sf-template ul li {
    width: calc(16.66% - 28px);
    height: 300px;
  }

  .sf-template ul {
    height: 490px;
  }
}

.el-select .el-input {
  width: 150px;
}

.el-input-group__append {
  background-color: #5486e5 !important;
  color: #fff !important;
  font-size: 22px;
  padding: 0 13px !important;
}

.el-input-group__append:hover {
  background-color: #71a0fa !important;
}
</style>
