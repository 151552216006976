// 文章相关请求模块
import request from '@/utils/request'

// 获取案件列表
export const getCase = params => {
  return request({
    method: 'GET',
    url: '/api/Case/query',
    // body 参数使用 data 设置
    // query 参数使用 params 设置
    // headers 参数使用headers 设置
    params
  })
}

// 新增案件
export const addCase = data => {
  return request({
    method: 'POST',
    url: '/api/Case/add',
    data
  })
}
// 删除案件
export const deleteCase = deleteId => {
  return request({
    method: 'DELETE',
    url: '/api/Case/delete',
    data: {
      id: deleteId
    }
  })
}

// 编辑案件
export const editCase = (CaseId, data) => {
  return request({
    method: 'PUT',
    url: '/api/Case/edit',
    params: {
      CaseId: CaseId
    },
    data
  })
}
// 根据案件id获取案件
export const getCaseId = id => {
  return request({
    method: 'GET',
    url: `/api/Case/${id}`
  })
}
// 询问配置事项
export const addQuestionConfig = data => {
  return request({
    method: 'POST',
    url: '/api/PersonQuestionConfig/config',
    data
  })
}
// 删除某个配置询问事项
export const deleteQuestionConfig = id => {
  return request({
    method: 'DELETE',
    url: '/api/PersonQuestionConfig/delete',
    data: {
      id: id
    }
  })
}
// 查询某个当事人配置询问事项列表
export const getList = id => {
  return request({
    method: 'GET',
    url: `/api/PersonQuestionConfig/list?PersonId=${id}`,
  })
}
// 问前准备
export const editReady = data => {
  return request({
    method: 'POST',
    url: '/api/PersonQuestionConfig/ready',
    data
  })
}

//案件办结

export const caseFinish = caseId => {
  return request({
    method: 'PUT',
    url: '/api/Case/finish',
    data: {
      id: caseId
    },
  })
}



