<template>
	<div class="login-container">
		<div class="logo">
			<img src="../../assets/sflogo.png">
		</div>
		<div class="sf-login-from login-form">
			<div class="tab-title">
				<span @click="cur=0" :class="{active:cur==0}">账号登录</span>
				<span @click="cur=1" :class="{active:cur==1}">短信登录</span>
			</div>
			<div class="tab-content">
				<div v-show="cur==0">					
					<el-form ref="login-form" :model="user" :rules="formRules">
						<el-form-item prop="userName">
							<el-input v-model="user.userName" placeholder="请输入账号"></el-input>
						</el-form-item>
						<el-form-item prop="password">
							<el-input v-model="user.password" placeholder="请输入密码" show-password></el-input>
						</el-form-item>
						<el-form-item prop="code" class="code-item">
							<el-input v-model="user.code" placeholder="请输入验证码"></el-input>
							<img :src="validatecode" @click="refreshCode" alt=""><img>
						</el-form-item>
						<el-form-item>
							<el-checkbox v-model="rememberPwd" @change="changeRemember">记住密码</el-checkbox>
						</el-form-item>
						<el-form-item>
							<el-button class="login-btn" type="primary" :loading="loginLoading" @click="onLogin">登 录</el-button>
						</el-form-item>
					</el-form>
				</div>
				<div v-show="cur==1">					
					<el-form ref="login-sms-form" :model="smsUser" :rules="phoneFormRules">
						<el-form-item prop="phone">
							<el-input v-model="smsUser.phone" placeholder="请输入手机号"></el-input>
						</el-form-item>
						<el-form-item prop="imgCode" class="code-item">
							<el-input v-model="smsUser.imgCode" placeholder="请输入图形验证码"></el-input>
							<img :src="validatecode" @click="refreshCode" alt=""><img>
						</el-form-item>
						<el-form-item prop="code" class="code-item">
							<el-input v-model="smsUser.code" placeholder="请输入手机验证码"></el-input>
							<el-button class="get-yzm" @click="refreshSmsCode" alt="" :disabled="smsCodeDesc == ''">{{smsCodeDesc == ''? smsCount+'秒' : smsCodeDesc}}</el-button>
						</el-form-item>
						<el-form-item>
							<el-button class="login-btn" type="primary" :loading="loginLoading" @click="onSmsLogin">登 录</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
		<footer class="footer">
			主办单位：舟山市司法局
		</footer>
	</div>
</template>

<script>
import { Constants } from '@constants'
import {
  login,
  smsLogin,
  validateCodeImage,
  validateCodeSms
} from '@/api/user'
import Base64 from 'base-64';
import { getCurrentUserInfo } from "@/api/user";

export default {
  name: 'LoginIndex',
  components: {},
  props: {},
  data () {
    return {
	  cur: 0,
      validatecode: {},
	  smsCount : 60,
	  smsCodeDesc:'获取验证码',
      user: {
        userName: '',
        password: '',
        captChaKey: '',
        code: '',
		imgCode: ''
      },
	  smsUser: {
        captChaKey: '',
        code: '',
		phone:''
      },
	  smsUserImg:{
		captChaKey: '',
        code: '',
		phone:'',
	  },
      // checked: false,
      loginLoading: false,
	  rememberPwd: false,
      formRules: {
        userName: [{
          required: true,
          message: '账号不能为空',
          trigger: 'change'
        }
        ],
        password: [{
          required: true,
          message: '密码不能为空',
          trigger: 'change'
        }
        ],
        code: [{
          required: true,
          message: '验证码不能为空',
          trigger: 'change'
        }
        ]
      },
	  phoneFormRules: {
        phone: [{
          required: true,
          message: '手机号不能为空',
          trigger: 'change'
        }],
        code: [{
          required: true,
          message: '验证码不能为空',
          trigger: 'change'
        }]
      }
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {
	  if(localStorage.getItem('expires')){
		let expiresDate	= Number(localStorage.getItem('expires'))
		let now = new Date().getTime()
		if(expiresDate < now){
			localStorage.removeItem('expires')
			localStorage.removeItem('userName')
			localStorage.removeItem('password')
		}
		if(localStorage.getItem('userName')){
		this.user.userName = Base64.decode(localStorage.getItem('userName'))
	  }
		if(localStorage.getItem('password')){
			this.user.password = Base64.decode(localStorage.getItem('password'))
		}
	  }
	  if(localStorage.getItem('rememberPwd')){
		this.rememberPwd = 'true' == localStorage.getItem('rememberPwd')
	  }
	
	this.refreshCode()
  },
  methods: {
    refreshCode () {
      validateCodeImage().then(res => {
        this.validatecode = res.image
        this.user.captChaKey = res.captchakey
      })
    },
	refreshSmsCode(){
		let _this = this
		if(_this.smsUser.phone == ''){
			_this.open4('请先输入手机号')
			return;
		}
		if(_this.smsUser.imgCode == ''){
			_this.open4('请先输入图形验证码')
			return;
		}
		_this.smsUserImg.captChaKey = _this.user.captChaKey
		_this.smsUserImg.code = _this.smsUser.imgCode
		_this.smsUserImg.phone = _this.smsUser.phone
		validateCodeSms(_this.smsUserImg).then(res => {
			_this.smsUser.captChaKey = res.captchakey
			_this.smsCodeDesc = ''
			var interval = setInterval(function(){
			if(_this.smsCount == 0){
				clearInterval(interval)
				_this.smsCodeDesc = '获取验证码'
				_this.smsCount = 60
			}
			_this.smsCount-- 
			}, 1000);
      	})
	},
    onLogin () {
      this.$refs['login-form'].validate((valid) => {
        if (valid) {
          login(this.user).then((res) => {
            console.log('login',res)
            if (res.success) {
				if(this.rememberPwd){
					Base64.encode(this.user.userName)
					localStorage.setItem('userName',Base64.encode(this.user.userName))
					localStorage.setItem('password',Base64.encode(this.user.password))
					let curTime=new Date().getTime() + 86400000*7
					let expiresDate = new Date(curTime).valueOf()
					localStorage.setItem('expires',String(expiresDate));
				}
				Constants.setLocalStorage(Constants.TOKEN, res.data.Authorization)
				this.generalRoutes()
            }else{
				this.open4(res.message)
			}
          }).catch(err => {
            // 提交失败后刷新验证码
            this.refreshCode()
            this.open4('验证码错误')
            // console.log(err)
          })
		 }
		})
	},
	onSmsLogin () {
      this.$refs['login-sms-form'].validate((valid) => {
        if (valid) {
          smsLogin(this.smsUser).then((res) => {
            console.log('login',res)
            if (res.success) {
				Constants.setLocalStorage(Constants.TOKEN, res.data.Authorization)
				this.generalRoutes()
            }else{
				this.open4(res.message)
			}
          }).catch(err => {
            // 提交失败后刷新验证码
            this.refreshCode()
            this.open4('验证码错误')
            // console.log(err)
          })
		 }
		})
	},
    open1 (val) {
      this.$message(val)
    },
    open2 (val) {
      this.$message({
        message: val,
        type: 'success'
      })
    },
    open3 (val) {
      this.$message({
        message: val,
        type: 'warning'
      })
    },
    open4 (val) {
      this.$message.error(val)
    },
	changeRemember(){
		console.log(this.rememberPwd)
		if(this.rememberPwd){
			localStorage.setItem('rememberPwd',this.rememberPwd);
		}else{
			localStorage.removeItem('rememberPwd')
		}
	},
	generalRoutes(){
		getCurrentUserInfo().then(res => {
					console.log('getCurrentUserInfo',res)
					if (res.success) {
						//设置登录账号信息到SessionStorage
						Constants.setSessionStorage(Constants.set("UserInfo", "UserInfo"), res.data,true);
						
						let activeRoutes = [
							{
							path: '/case',
							component: 'Layout',
							meta:{title:'案件管理',icon:'el-icon-tickets'},
							children: [
								{
								path: '/case/all',
								name: 'caseAll',
								component: 'case-all',
								meta:{title:'全部案件'}
								},
								{
								path: '/case',
								name: 'case',
								component: 'case',
								meta:{title:'待办案件'}
								},
								{
								path: '/cased',
								name: 'cased',
								component: 'cased',
								meta:{title:'已办案件'}
								},

							]
							},
							{
							path: '/template',
							component: 'Layout',
							meta:{title:'文件模板',icon:'el-icon-document'},
							children: [
								{
								path: '/template/index',
								name: 'template',
								component: 'template',
								meta:{title:'文件模板'}
								}
							]
							},
							{
							path: '/settings',
							component: 'Layout',
							meta:{title:'系统管理',icon:'el-icon-setting'},
							children: [
								{
								path: '/settings/users',
								name: 'users',
								component: 'settings/users',
								meta:{title:'用户管理'}
								},
								// {
								// path: '/settings/roles',
								// name: 'roles',
								// component: 'settings/roles',
								// meta:{title:'角色管理'}
								// },
								{
								path: '/settings/dictionary',
								name: 'dictionary',
								component: 'settings/dictionary',
								meta:{title:'系统字典'}
								},
								// {
								// path: '/settings/menu',
								// name: 'menu',
								// component: 'settings/menu',
								// meta:{title:'菜单管理'}
								// }
							]
							}
						]
						
						Constants.setSessionStorage('activeRoutes', activeRoutes,true);

						this.$router.push({
							name: 'case'
						})
					}
				});
	}
  }
}
</script>

<style >
	.login-container {
		font-size: 16px;
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 60px;
	}
	.login-form {
		padding: 20px 40px;
		width: 420px;
		background-color: rgba(255,255,255,.1);
		border-radius: 10px;
		border: 1px solid rgba(175, 212, 254, .3)
	}

	.login-btn {
		width: 100%;
	}
	.footer {
		position: fixed;
		bottom: 0;
		width: 100%;
		height: 42px;
		color: #FFFFFF;
		background-image: url(../../assets/footerbg.png);
		padding-top: 20px;
		text-align: center;
		font-size: 14px;
		opacity: 0.8;
		letter-spacing: 1px;
	}
	.logo img{
		height: 200px;
	}
	.sf-login-from .el-input__inner{
		background-color: rgba(42,78,148,0.05) !important;
		/* opacity:0.2; */
		color: #fff !important;
		border: #5e96ce 1px solid !important;
		border-radius: 05px !important;
		height: 42px !important;
		line-height: 42px !important;
	}
	.sf-login-from .el-form-item__content{
		line-height: 16px !important;
	}
	.sf-login-from .el-button--primary{
		background-color: #db7534 !important;
		border: #db7534 1px solid !important;
		border-radius: 5px !important;
		height: 46px !important;
		font-size: 16px;
	}
	.sf-login-from .el-button--primary:hover{
		background-color: #ff8233 !important;
		border: #ff8233 1px solid !important;
	}
	.sf-login-from .el-checkbox__label{
		color: #ffffff !important;
		letter-spacing: 1px;
	}
	.sf-login-from .el-checkbox__inner{
		background-color: rgba(42,78,148,0.05) !important;
		border-radius: 3px !important;
		border: #88ccfb 1px solid !important;
	}
    .tab-title {
		margin-bottom: 30px;
		font-size: 24px;
		color: #fff;
		display: flex;
		justify-content: center;
	}
	.tab-title span {
		position: relative;
		margin-right: 32px;
		line-height: 40px;
	}
	.tab-title span:last-child {
		margin-right: 0;
	}
	.tab-title span.active::after{
		position: absolute;
		top: 44px;
		left: 50%;
		margin-left: -20px;
		width: 40px;
		height: 3px;
		background-color: #ff8233;
		content: '';
	}
	.tab-title span:not(.active) {
		opacity: .8;
		cursor: pointer;
	}
	.code-item .el-form-item__content {
		display: flex;
		justify-content: space-between
	}
	.code-item .el-form-item__content .el-input {
		flex: 1;
		padding-right: 8px;
		width: auto;
	}
	.code-item img {
		height: 42px;
		border-radius: 5px;
	}
	.code-item .get-yzm {	
		padding: 0 12px;
		height: 42px;
		color: #333;
		border-radius: 5px;
		background: #e6f1fe;
		border: 0;
		cursor: pointer;
	}

</style>
