<template>
  <el-form ref="form" :model="detailEditForm" label-width="90px" size="mini">
    <el-form-item label="当事人姓名">
      <el-input v-model="detailEditForm.name"></el-input>
    </el-form-item>
    <el-form-item label="当事人类型">
      <el-select class="select-type" v-model="detailEditForm.Peotype" placeholder="请选择当事人类型">
        <el-option label="原告" value="原告"></el-option>
        <el-option label="被告" value="被告"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="身份类型">
      <el-select class="select-type" v-model="detailEditForm.IDtype" placeholder="请选择当事人类型">
        <el-option label="身份类型一" value="类型一"></el-option>
        <el-option label="身份类型二" value="类型二"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="证件号码">
      <el-input v-model="detailEditForm.ID"></el-input>
    </el-form-item>
    <el-form-item label="联系电话">
      <el-input v-model="detailEditForm.phone"></el-input>
    </el-form-item>
    <el-form-item label="询问状态">
      <el-select class="select-type" v-model="detailEditForm.state" placeholder="请选择当事人类型">
        <el-option label="待配置" value="待配置"></el-option>
        <el-option label="已配置" value="已配置"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="被询问次数">
      <el-input v-model="detailEditForm.inquiryTime"></el-input>
    </el-form-item>
    <el-button type="primary" @click="detailIncreaseSaveBtn">保存</el-button>
  </el-form>
</template>

<script>

export default {
  name: 'edit',
  components: {
  },
  data () {
    return {
      detailEditForm: {
        name: '案件名称',
        Peotype: '行政',
        IDtype: '类型一',
        ID: '20134567',
        phone: '13567896789',
        state: '待配置',
        inquiryTime: '4'
      }
    }
  },
  methods: {
    detailIncreaseSaveBtn () {
      this.$message({
        message: '恭喜你，保存成功！',
        type: 'success'
      })
    }
  }
}
</script>

<style scoped>
.select-type {
  display: flex !important;
}
</style>
