<template>
	<el-container class="layout-container">
		<el-header class="header">
			<div>
				<div class="layout-logo"><img src="../../assets/sflogo2.png"></div>
				<div class="pro-title">远程援助</div>
			</div>
			<div class="sf-user">
				<sf-user></sf-user>
			</div>
			<!-- <el-dropdown>
				<div class="avatar-wrap">
					 <img class="avatar" :src="user.photo" alt=""> 
					<span>欢迎您，</span>
					<span>{{ user.UserName }}</span>
					<i class="el-icon-arrow-down el-icon--right"></i>
				</div>
				
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item>设置</el-dropdown-item>
					<el-dropdown-item @click.native="onModifyPassword">修改密码</el-dropdown-item>
					<el-dropdown-item @click.native="onLogout">退出</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown> -->
		</el-header>
		<el-container>
			<el-aside class="aside" width="auto">
				<app-aside class="aside-menu" :is-collapse="isCollapse" />
			</el-aside>
			<el-main class="main">
				<router-view />
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
	import sfUser from './components/user'
	import AppAside from './components/aside'
	import globalBus from '@/utils/global-bus'

	export default {
		name: 'LayoutIndex',
		components: {
			AppAside,
			sfUser
		},
		props: {},
		data() {
			return {
				isCollapse: false // 侧边菜单栏展开状态
			}
		},
		computed: {},
		watch: {},
		created() {
			// 组件初始化好，请求获取用户资料
			// globalBus.$on('update-user', (data) => {
			// 	// this.user.UserName = data.name
			// 	// this.user.photo = data.photo
			// })
		},
		mounted() {},
		methods: {
		}
	}
</script>

<style scoped lang="less">
	.layout-container {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
.sf-user {
		float: right;
		padding: 0;
	}
	.aside {
		background-color: #666;

		.aside-menu {
			height: 100%;
		}
	}

	.header {
		color: #fff;
		background-color: #1e4ca5;
		// border-bottom: 1px solid #ccc;
		height: 75px !important;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.main {
		background-color: #f0f4fb;
	}

	.avatar-wrap {
		display: flex;
		align-items: center;

		.avatar {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			margin-right: 10px;
		}
	}
</style>
<style type="text/css">
	.aside {
		background-image: url(../../assets/homebg.jpg);
		background-size: cover;
	}

	.el-header {
		padding: 0 30px 0 0 !important;
	}

	.layout-logo {
		float: left;

	}

	.layout-logo img {
		float: left;
		height: 75px;
	}

	.pro-title {
		float: left;
		display: block;
		line-height: 75px;
		font-size: 20px;
		letter-spacing: 3px;

	}

	.pro-title:before {
		content: "| ";

	}

	.el-dropdown {
		color: #fff !important;
	}

	.header span {
		letter-spacing: 1px;
	}
</style>
