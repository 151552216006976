import Vue from 'vue'
import VueRouter from 'vue-router'
import { Constants } from '@constants'

import Login from '@/views/login'
import Home from '@/views/home'
import Towechart from '@/views/towechart'
import Layout from '@/views/layout'
import Detail from '@/views/case/detail'
import Material from '@/views/case/material'
import DetailIncrease from '@/views/case/detail-increase'
import Plan from '@/views/case/plan'
import Process from '@/views/case/process'
import Video from '@/views/case/video'
import CasedDetail from '@/views/cased/detail'
import InquiryDetail from '@/views/cased/inquiryDetail'


// import Case from '@/views/case'
// import Cased from '@/views/cased'
// import Seal from '@/views/seal'
// import Template from '@/views/template'
// import Settings from '@/views/settings'
// import Users from '@/views/settings/users'
// import Roles from '@/views/settings/roles'
// import Dictionary from '@/views/settings/dictionary'
// import Statistics from '@/views/statistics'
// import CaseAll from '@/views/case-all/index'


Vue.use(VueRouter)

// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}


const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/towechart',
    name: 'towechart',
    component: Towechart
  },
  {
    path: '/',
    component: Layout,
    children: [
        {
          path: '/case/detail',
          name: 'detail',
          meta: { activeMenu: '/case/detail' },
          hidden: true,
          component: Detail
        },
        {
          path: '/case/material',
          name: 'meterial',
          meta: { activeMenu: '/case/material' },
          hidden: true,
          component: Material
        },
        {
          path: '/case/detail-increase',
          name: 'detail-increase',
          meta: { activeMenu: '/case/detail-increase' },
          hidden: true,
          component: DetailIncrease
        },
        {
          path: '/case/plan',
          name: 'plan',
          meta: { activeMenu: '/case/plan' },
          hidden: true,
          component: Plan
        },
        {
          path: '/case/process',
          name: 'process',
          meta: { activeMenu: '/case/process' },
          hidden: true,
          component: Process
        },
        {
          path: '/case/video',
          name: 'video',
          meta: { activeMenu: '/case/video' },
          hidden: true,
          component: Video
        },
        {
          path: '/cased/detail',
          name: 'casedDetail',
          component: CasedDetail
        },
        {
          path: '/cased/detail/inquiryDetail',
          name: 'InquiryDetail',
          component: InquiryDetail
        },
    ]
  }
]

const router = new VueRouter({
  routes
})
// 路由导航守卫，说白了所有页面的导航都会经过这里
// 守卫页面的导航的
// to:要去的路由页面信息
// from:来自哪的路由信息
// next:放行方法

let isAddRouter = false;
router.beforeEach((to, from, next) => {
  // 如果要访问的页面不是login，校验登录状态
  // 如果没有登录，则跳转到登录页面
  // 如果登录了，则允许通过
  // 允许通过
  let userInfo = sessionStorage.getItem('UserInfo')

  // 校验非登录页面的登录状态
  if (to.path !== '/login') {
    if(userInfo && !isAddRouter){
      isAddRouter = true

      let activeRoutes = JSON.parse(Constants.getSessionStorage('activeRoutes'))
      // console.log('activeRoutes',activeRoutes)
      let newRoutes = []
      activeRoutes.forEach(parent => {
        let childrenArr = []
        parent.children.forEach(children => {
          childrenArr.push({
            path: children.path,
            name: children.name,
            component: (resolve) => require([`@/views/`+children.component],resolve),
          })
        })
        newRoutes.push({
          path:'/',
          component:Layout,
          children :childrenArr
        })
      })
      // console.log('newRoutes',newRoutes)

      router.options.routes = newRoutes;
      router.addRoutes(newRoutes)
      next({ ...to, replace: true })
    }else{
      next()
    }
  } else {
    // 登录页面，正常允许通过
    next()
  }
})

export default router
