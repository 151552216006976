<template>
  <div class="article-container sf">
    <div slot="header" class="clearfix sf-breadcrumb">
      <!--面包屑-->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>案件管理</el-breadcrumb-item>
        <el-breadcrumb-item>待办案件</el-breadcrumb-item>
        <el-breadcrumb-item>案件详情</el-breadcrumb-item>
        <el-breadcrumb-item>问前准备</el-breadcrumb-item>
      </el-breadcrumb>
      <!--/面包屑-->
    </div>
    <el-card class="filter-card">

      <!--数据表格筛选-->
      <el-form class="btn-top" ref="form" label-width="40px">
        <div style="float:left">
          <div style="float:left;margin-left: 10px" class="btn-button" v-for="item in inquiryList" :key="item.TemplateId">
            <el-button @click="templatebtn(item)" :type="selectId == item.QuestionConfigId ? 'primary' : ''" >{{item.DocType}}</el-button>
          </div>
          <div style="float:left;margin-left: 10px">
            <el-button @click="dialogInquiryVisible = true" class="el-icon-circle-plus"></el-button>
            <el-button @click="deleteBtn" class="el-icon-delete"></el-button>
          </div>
        </div>
        <div></div>
        <!--/数据表格筛选-->
        <div style="float:right">
          <!-- <el-button-group style="margin-right: 10px"> -->
            <!-- <el-button @click="dialogInquiryVisible = true" class="el-icon-edit-outline"></el-button> -->
            <!-- <el-button @click="deleteBtn" class="el-icon-delete"></el-button> -->
          <!-- </el-button-group> -->
          <!-- <el-button type="warning" class="el-icon-check" @click="ready">准备完毕</el-button> -->
          <el-button class="el-icon-back" @click="back" >返回</el-button>

        </div>
      </el-form>
      <!--/数据表格筛选-->

      <!-- <div class="template-header">
        <h2>{{title}}</h2>
      </div>
      <div class="template-body">
        <div class="box2">
          <span>{{template}}</span>
          <div>
            <el-tiptap
              v-model="questionContent"
              :extensions="extensions"
              height="350"
              lang="zh"
              placeholder="请输入内容"
            ></el-tiptap>
            <div class="custom-mount"></div>
          </div>
        </div>
      </div> -->
      <iframe :src="wordOnlineUrl" frameborder="0" class="pc iframe" scrolling="auto" style="width: 100%;height:650px"></iframe>
      
      <!--按钮-->
      <div class="btn-bottom">
        <el-button type="warning" @click="nextButton">准备完毕,进入下一项</el-button>
      </div>
      <!--/按钮-->
    </el-card>
    <el-dialog class="increaseForm" v-if="dialogInquiryVisible"  title="添加询问事项" append-to-body :visible.sync="dialogInquiryVisible"
               :close-on-click-modal="false">
      <InquiryPlus :dialogInquiryVisible="dialogInquiryVisible" :PersonId="personId" :ifPlus="true"
               @editChanged="editDataChange($event)"/>
    </el-dialog>
  </div>
</template>

<script>
import InquiryEdit from './components/inquiry-edit'
import { getList, editReady, deleteQuestionConfig } from '@/api/case'
import { getWordOnlineUrl } from '@/api/wordOnline'

import InquiryPlus from './components/inquiry-plus'
import {
  // ElementTiptap,
  // Doc,
  // Text,
  // Paragraph,
  // Heading,
  // Bold,
  // Underline,
  // Italic,
  // Strike,
  // ListItem,
  // BulletList,
  // OrderedList,
  // TodoItem,
  // TodoList,
  // HorizontalRule,
  // Fullscreen,
  // Preview,
  // CodeBlock,
  // TextColor

  ElementTiptap,
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  Link,
  Image,
  CodeBlock,
  Blockquote,
  TodoItem,
  TodoList,
  TextAlign,
  FontSize,
  FontType,
  Fullscreen,
  TextHighlight,
  TextColor,
  FormatClear,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  History,
  TrailingNode,
  HardBreak,
  HorizontalRule,
  LineHeight,
  Indent,

  Preview
} from 'element-tiptap'
import 'element-tiptap/lib/index.css'
import WebOfficeSDK from '../../utils/web-office-sdk-v1.1.17.es'

export default {
  name: 'Template',
  components: {
    'el-tiptap': ElementTiptap, InquiryEdit, getList, InquiryPlus
  },
  props: {},
  data () {
    return {
      template: '',
      title: '',
      selectId: -1,
      selectName:'',
      flag: false,
      input1: '',
      input2: '',
      input3: '',
      select: '',
      dialogFormVisible: false,
      personId: 0,
      inquiryList: [],
      dialogInquiryVisible: false,
      questionContent: '',
      fileId:'',
      wordOnlineUrl:'', //在线word读取地址
      extensions: [
        new Doc(), // 必须项
        new Text(), // 必须项
        new Paragraph(), // 必须项
        new Heading({ level: 6 }), // 标题
        new Bold({ bubble: true }), // 加粗 bubble: true 在气泡菜单中渲染菜单按钮
        new Underline({ bubble: true, menubar: false }), // 下划线 bubble: true, menubar: false 在气泡菜单而不在菜单栏中渲染菜单按钮
        new Italic({ bubble: true }), // 斜体
        new Strike({ bubble: true }), // 删除线
        new ListItem(), // 使用列表必须项
        new BulletList({ bubble: true }), // 无序列表
        new OrderedList({ bubble: true }), // 有序列表
        new Link(), // 链接
        new Image(), // 图片
        new CodeBlock({ bubble: true }), // 代码块
        new Blockquote(), // 引用
        new TodoItem(), // 任务列表必须项
        new TodoList(), // 任务列表
        new TextAlign({ bubble: true }), // 文本对齐方式
        new FontSize({ bubble: true }), // 字号
        new FontType({ bubble: true }), // 字体
        new Fullscreen(), // 全屏
        new TextHighlight({ bubble: true }), // 文本高亮
        new TextColor({ bubble: true }), // 文本颜色
        new FormatClear({ bubble: true }), // 清除格式
        new Table({ resizable: true }), // 表格
        new TableHeader(), // 表格必须项
        new TableCell(), // 表格必须项
        new TableRow(), // 表格必须项
        new History(), // 撤销
        new TrailingNode(), // 重做
        new HardBreak(), // 分割线
        new HorizontalRule(), // 行距
        new LineHeight(), // 增加缩进
        new Indent(), // 减少缩进
        new Preview()
      ]
    }
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
    this.initialization()
    // var demo = WebOfficeSDK.config({
    //   url: 'https://wwo.wps.cn/office/s/?_w_appid=appid��e867fcfb4e8940969f15dbe967696a0c&_w_fileName=test1.docx&_w_userId=123&_w_signature=miTRc5Us8P90iCj35yU%2f5%2f2iFNU%3d', // 如果需要通过js-sdk传递token方式鉴权，则需要包含_w_tokentype=1参数
    //   mount: document.querySelector('.custom-mount')
    // })
    // demo.on('fileOpen', function (data) {
    //   console.log(data.success)
    // })
  },
  methods: {
    async initialization () {
      this.personId = this.$route.query.PersonId
      this.callGetList(true)
    },
    templatebtn (val) {
      this.selectId = val.QuestionConfigId
      this.selectName = val.DocType
      this.fileId = val.FileId
      // let inquiryStatus = (this.userdata.InquiryStatus != 4 && this.userdata.InquiryStatus != 5)
      getWordOnlineUrl(this.fileId,true).then((res) => {
        if (res) {
          // let url = res.Url.replace("oos","fw").replace("/we/","/wosedit/").replace("/wv/","/wosview/")
          this.wordOnlineUrl = res.Url
        }
      })
      this.template = val.TemplateName
      this.questionContent = val.QuestionContent
    },
    back () {
      this.$router.push('/case/detail?CaseId=' + this.$route.query.CaseId + '&&PersonId=' + this.$route.query.PersonId)
    },
    editDataChange (params) {
      // console.log("params",params)
      this.callGetList()
      this.dialogInquiryVisible = false
    },
    // 准备完毕
    async ready () {
      await this.questionContentChange()
      await this.edit()
    },
    edit () {
      const search = {
        id: this.personId,
        items: this.inquiryList
      }
      editReady(search).then(res => {
        // console.log("editReady",res)
        this.$message({
          message: '恭喜你，保存成功！',
          type: 'success'
        })
        this.flag = true
      })
    },
    // 内容编辑
    questionContentChange () {
      // console.log("this.inquiryList1",this.inquiryList)
      for (const i in this.inquiryList) {
        if (this.inquiryList[i].QuestionConfigId == this.selectId) {
          this.inquiryList[i].QuestionContent = this.questionContent
        }
      }
      // console.log("this.inquiryList2",this.inquiryList)
    },
    callGetList (val) {
      getList(this.personId).then(res => {
        this.inquiryList = res
        let tf = true
        for (const i of this.inquiryList) {
          if (this.selectId == i.QuestionConfigId) {
            tf = false
          }
        }
        if (val || tf) {
          this.templatebtn(this.inquiryList[0])
        }
      })
    },
    // 删除
    callDelete () {
      if (this.selectId == -1) {
        this.$message({
          type: 'info',
          message: '未选择'
        })
      } else {
        deleteQuestionConfig(this.selectId).then(res => {
          console.log('deleteQuestionConfig', res)
          this.selectId = -1
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.callGetList()
        })
      }
    },
    loadArticles (page = 1) {
    },
    nextButton () {
      this.questionContentChange()
      const search = {
        id: this.personId,
        items: this.inquiryList
      }
      editReady(search).then(res => {
        // console.log("editReady",res)
        this.$router.push('/case/video?CaseId=' + this.$route.query.CaseId + '&&PersonId=' + this.$route.query.PersonId)
      })
    },
    deleteBtn () {
      this.$confirm('确认删除'+this.selectName+'，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.callDelete()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    }
  }
}
</script>

<style scoped>
.filter-card {
  margin-bottom: 30px;
}
.btn-top{
  width: 100%;
  float: left;
  /* display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: auto 1fr auto; */
}
.btn-button{
  /* width: auto; */
}

.list-table {
  margin-bottom: 20px;
}

.article-cover {
  width: 60px;
  background-size: cover;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

.increaseForm {
  text-align: center;
}

.el-select .el-input {
  width: 130px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.sf-breadcrumb {
  padding-bottom: 13px;
  border-bottom: #cbddff 1px solid;
  margin-bottom: 20px;
}

.sf-breadcrumb .el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  font-weight: normal !important;
}

.text-align-r {
  text-align: right;
}

.sf-search-more {
  border: #d8e3f8 1px solid;
  border-radius: 4px;
  padding: 20px 20px 0 20px;
  margin-top: 20px;
  background: #e7effd;
}

.sf-template {
  display: flex;
  border: #dcdfe6 1px solid;
  margin: 20px 0;
  padding: 10px;
  overflow-y: scroll;
}

.sf-template ul {
  width: 100%;
  height: 330px;
  padding: 0;
  margin: 0;
}

.sf-template ul li {
  box-sizing: border-box;
  float: left;
  display: flex;
  width: calc(20% - 28px);
  height: 270px;
  margin: 14px;
  border: #e7e9f0 1px solid;
  box-shadow: 3px 3px 3px #eee;
  background-color: #f4f8ff;
}

.sf-template ul li a {
  width: 100%;
  padding: 20px;
  font-size: 24px;
  text-align: center;
  line-height: 38px;
  font-family: 宋体;
  font-weight: bold;
}

.template-header {
  padding: 30px 0 0 0;
  text-align: center;
}

.template-body {
  border: solid 1px #EBEEF5;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.box1, .box2 {
  border: solid 1px #EBEEF5;
  text-align: center;
}

.main-box1 {
  width: 40%;
}

.main-box2 {
  width: 60%;
}

.box1 span, .box2 span {
  height: 30px;
  line-height: 30px;
  background: #eeeeee;
  width: 100%;
  display: inline-flex;
  justify-content: center;
}

.box1 {
  height: 200px;
}

.box2 {
  width: 100%;
  height: 402px;
}

.btn-bottom {
  display: flex;
  flex-direction: row-reverse;
}

@media (min-width: 1920px) {
  .sf-template ul li {
    width: calc(16.66% - 28px);
    height: 300px;
  }

  .sf-template ul {
    height: 490px;
  }
}

.el-select .el-input {
  width: 150px;
}

.el-input-group__append {
  background-color: #5486e5 !important;
  color: #fff !important;
  font-size: 22px;
  padding: 0 13px !important;
}

.el-input-group__append:hover {
  background-color: #71a0fa !important;
}

.divcenter{
  width: 100%;
  height: 170px;
  display: grid;
  align-items: center;
  justify-items: center;
}
.inquiry{
  width: 100%;
  height: auto;
  max-height: 150px;
  overflow: auto;
}
.inquiry-piece{
  margin: 1% 0 0 0;
  cursor: pointer;
}
.highlight{
  color: #409eff;
}
.custom-mount { width: 100%; height: 100%; }
</style>
