// 文件模板管理请求模块
import request from '@/utils/request'

/**
 * 获取文件模板信息
 * @param {*} id
 * @returns
 */
export const getTemplateId = id => {
  return request({
    method: 'GET',
    url: `/api/Template/${id}`
  })
}

/**
 * 添加文件模板信息
 * @param {*} data
 * @returns
 */
export const addTemplate = data => {
  return request({
    method: 'POST',
    url: '/api/Template/add',
    data
  })
}

/**
 * 删除文件模板信息
 * @param {*} deleteId
 * @returns
 */
export const deleteTemplate = deleteId => {
  return request({
    method: 'DELETE',
    url: '/api/Template/delete',
    data: {
      id: deleteId
    }
  })
}

/**
 * 编辑文件模板信息
 * @param {*} data
 * @returns
 */
export const editTemplate = (templateId, data) => {
  return request({
    method: 'PUT',
    url: '/api/Template/edit',
    params: {
      id: templateId
    },
    data
  })
}

/**
 * 查询文件模板信息
 * @param {*} params
 * @returns
 */
export const queryTemplate = params => {
  return request({
    method: 'GET',
    url: '/api/Template/query',
    params
  })
}
