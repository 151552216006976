<template>
  <div>
    <el-form ref="addForm" :model="increaseForm" label-width="80px" size="mini">
      <div class="tip">
        <span><i class="el-icon-s-opportunity" style="color: #E6A23C;"></i><i
          style="color: #E6A23C; font-style: normal;">友情提示：</i>请选择需要签章的文件。</span>
      </div>
      <div class="mainbox">
        <div class="box" v-for="item in inquiryList" :key='item.FileId'
             :class="{changeStyle: arr.includes(item.FileId)}" @click="selectSealItem(item.FileId)">
          <a>
            <h1>{{ item.TemplateName }}</h1>
            <!--            <span>{{ item.englishName }}<i class="el-icon-question"></i></span>-->
          </a>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { getList } from '@/api/case'

export default {
  name: 'InquirySelf',
  components: {},
  props: ['dialogInquiryVisible','InquiryStatus','PersonId'],
  // props: {
  //   dialogInquiryVisible: {
  //     type: Boolean,
  //     default: true // 默认值
  //   },
  //   InquiryStatus: {
  //     type: Number,
  //     default: 0
  //   },
  //   PersonId: {
  //     type: Number,
  //     default: 0
  //   }
  // },
  data () {
    return {
      changeSelectStyle: '',
      arr: [],
      signFileIdList: [],
      increaseForm: {
        personId: 0,
        templateIds:[]
      },
      // 绑定数据
      inquiryList: [
        // { name: '询问通知书', englishName: 'Inquiry note' },
        // { name: '权利义务告知书', englishName: 'Notification of rights and obligations' },
        // { name: '询问笔录', englishName: 'Identifivation record' },
        // { name: '辨认嫌疑人', englishName: 'Identify suspects' },
        // { name: '辨认笔录', englishName: 'Identifivation record' },
        // { name: '调取证据通知书', englishName: 'Notice of taking evidence' },
        // { name: '接受/出示证据', englishName: 'Acceptance / presentation of evidence' },
        // { name: '调取证据清单', englishName: 'Access to evidence list' },
        // { name: '接收证据材料清单', englishName: 'List of admissible evidence materials' }
      ],
      search:{
        keyword: '',
        pageSize: 12,
        page: 1,
      }
    }
  },
  created () {
    this.loadTemplates()
  },
  methods: {
    loadTemplates () {
      getList(String(this.PersonId)).then(res => {
        console.log('res',res)
        this.inquiryList = res
        this.arr=[]
        this.increaseForm.templateIds=[]
      })
    },
    // 选中样式切换
    selectSealItem (i) {
      if (this.arr.indexOf(i)!=-1) {
        this.arr = this.arr.filter(function (ele) {
          return ele !== i
        })
        let list = []
        for(let j of this.signFileIdList){
          if(j!=i){
            list.push(j)
          }
        }
        this.signFileIdList=list
      } else {
        this.arr.push(i)
        this.signFileIdList.push(i)
      }
      this.$emit('fileSelected', this.signFileIdList);
    },
  }
}
</script>

<style scoped>
.select-type {
  display: flex !important;
}

.tip {
  text-align: left;
  border: solid 1px #DCDFE6;
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
  margin-bottom: 20px;
}

.box a {
  height: 70px;
  width: 170px;
  border: solid 1px #DCDFE6;
  padding: 20px;
  text-align: left;
  margin-bottom: 20px;
  display: inline-block;
  text-decoration: none;
  color: #606266;
}

.box a:hover, .changeStyle a{
  color: #409EFF;
}

.box a {
  cursor: pointer;
}

.box a h1 {
  font-size: 20px;
  margin: 0;
}

.box a span {
  margin-top: 20px;
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}

.box a span i {
  font-size: 20px;
}

.mainbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
