import axios from 'axios'
// 文章相关请求模块
import request from '@/utils/request'

// 根据流程Id获取案件签章信息
export const caseSignGet = flowId => {
  return request({
    method: 'GET',
    url: `/api/CaseSign/${flowId}`,
  })
}

// 创建案件签章(不强制要求当前案件下所有人都完成签章)
export const caseSignCreate = data => {
  return request({
    method: 'POST',
    url: '/api/CaseSign/create',
    data
  })
}
// 开启案件签章流程
export const caseSignStart = data => {
  return request({
    method: 'PUT',
    url: '/api/CaseSign/start',
    data
  })
}

// 查询
export const caseSignQuery = params => {
  return request({
    method: 'GET',
    url: '/api/CaseSign/query',
    params
  })
}

// 结束案件签章流程
export const caseSignEnd = data => {
  return request({
    method: 'PUT',
    url: '/api/CaseSign/end',
    data
  })
}

// 撤销案件签章流程
export const caseSignRevoke = data => {
  return request({
    method: 'PUT',
    url: '/api/CaseSign/revoke',
    data
  })
}


