//公用模板请求模块，如文件上传
import request from '@/utils/request'

/**
 * 文件上传
 * @param {*} data 
 * @returns 
 */
export const upload = data => {
  return request({
    url: '/api/Common/fileupload',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}
