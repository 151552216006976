<template>
	<div class="page-container">
		<div class="header">
			<div class="title"><img src="../../static/images/logo.png" class="logo">舟山市法律援助中心</div>
		</div>
		<div class="main-container">
			<div class="info-box">
				<div class="message-title">您好，您收到一份视频会议邀请。</div>
				<div class="way-item">
					<div class="way-name">
						<em>1</em>方法一
					</div>
					<div class="way-intro">点击下方红色按钮打开微信小程序"智慧远程法律服务"，输入您的姓名和身份证号登录并进入会议</div>
				</div>
				<div class="way-item">
					<div class="way-name">
						<em>2</em>方法二
					</div>
					<div class="way-intro">打开微信，搜索"智慧远程法律服务"小程序，进入小程序后输入您的姓名和身份证号登录并进入会议</div>
				</div>
			</div>
			<div class="btn"><button type="button" @click="redirectToWechart"><img src="../../static/images/icon-xcx.png">点击打开微信小程序</button></div>
		</div>
	</div>
</template>

<script>
import { generateUrlLink } from '@/api/towechart'
	export default {
		name: 'towechart',
		components: {
		},
		data(){
			return {
				url:''
			}
		},
		mounted() {
			generateUrlLink({
				Path:'',
				Query:''
			}).then(res => {
				this.url = res.url_link
      		})
		},
		methods: {
			redirectToWechart(){
				console.log("towechart",this.url)
				window.location.href = this.url
			}
		},
	}
</script>


<style scoped>
	@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300italic,600,600italic");
</style>
<style scoped src='../../static/css/main.css'>
	
</style>
<style>
	body{
		padding:0;
		margin:0;
	}
</style>
