<template>
  <el-form ref="peopleForm" :model="peopleForm" label-width="100px" size="mini" :rules="formRules">
    <el-form-item label="案件序号" prop="caseId" style="display: none">
      <el-input v-model="peopleForm.caseId">{{this.CaseId}}</el-input>
    </el-form-item>
    <el-form-item label="姓名" prop="name">
      <el-input v-model="peopleForm.name"></el-input>
    </el-form-item>
    <el-form-item label="当事人类型" prop="personType">
      <el-select class="select-type" v-model="peopleForm.personType" placeholder="请选择当事人类型">
        <el-option v-for="item in caseData" :key="item.Value" :label="item.Name" :value="item.Value"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="证件类型" prop="cardType">
      <el-select class="select-type" v-model="peopleForm.cardType" placeholder="请选择证件类型">
        <el-option v-for="item in cardTypeList" :key="item.Value" :label="item.Name" :value="item.Value"></el-option>
        <!-- <el-option label="内地" value="内地"></el-option> -->
        <!-- <el-option label="香港" value="香港"></el-option> -->
      </el-select>
    </el-form-item>
    <el-form-item label="身份证号" prop="idCard">
      <el-input v-model="peopleForm.idCard"></el-input>
    </el-form-item>
    <el-form-item label="联系电话" prop="phone">
      <el-input v-model="peopleForm.phone"></el-input>
    </el-form-item>
    <el-form-item label="居住地址" prop="address">
      <el-input v-model="peopleForm.address"></el-input>
    </el-form-item>
    <el-form-item label="备注" prop="remark">
      <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="peopleForm.remark">
      </el-input>
    </el-form-item>
    <el-form-item label="证件照片" prop="photoPath">
      <div class="peoPic">
        <el-upload class="centerImg" action="" :class="{ hide: hideUploadIcon }"
                   list-type="picture-card" :on-remove="handleRemove" :http-request="uploadImage"
                   :limit="1">
          <i class="el-icon-plus"></i>
          <div slot="tip" class="el-upload__tip">证件照</div>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="peopleForm.photoPath" alt=""/>
        </el-dialog>
        <el-upload class="centerImg" action="" :class="{ hide: hideUploadIcon }"
                   list-type="picture-card" :on-remove="handleRemove" :http-request="uploadImageFront"
                   :limit="1">
          <i class="el-icon-plus"></i>
          <div slot="tip" class="el-upload__tip">身份证正面照</div>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="peopleForm.idCardFrontPhotoPath" alt=""/>
        </el-dialog>
        <el-upload class="centerImg" action="" :class="{ hide: hideUploadIcon }"
                   list-type="picture-card" :on-remove="handleRemove" :http-request="uploadImageBack"
                   :limit="1">
          <i class="el-icon-plus"></i>
          <div slot="tip" class="el-upload__tip">身份证背面照</div>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="peopleForm.idCardBackPhotoPath" alt=""/>
        </el-dialog>
      </div>
    </el-form-item>
    <el-button type="primary" @click="peopleIncreaseSaveBtn">保存</el-button>
  </el-form>
</template>

<script>
import { addPerson } from '@/api/personConcerned'
import { upload } from '@/api/common'
import { getDictionaryByTypeCode } from '@/api/dictionary'

export default {
  name: 'increase',
  props: {
    dialogPeoVisible: {
      type: Boolean,
      default: true // 默认值
    },
    CaseId: {
      type: [Number, String],
      default: 123
    }
  },
  data () {
    const numberRules = {
      testIdCard (txt) {
        return (rule, value, callback) => {
          const IDCardReg = /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[0-2])(([0-2][1-9])|10|20|30|31)\d{3}(\d|X|x)$/
          if (value) {
            if (IDCardReg.test(value)) {
              callback()
            } else {
              callback(new Error('身份证号格式不正确'))
            }
          } else {
            callback(new Error('请输入身份证号'))
          }
        }
      },
      testPhone (txt) {
        return (rule, value, callback) => {
          if (!value) {
            callback(new Error('请输入联系方式'))
          } else {
            const reg = /^1[3|4|5|6|7|8|9]\d{9}$/
            // const phoneReg = /^1[34578]\d{9}$/
            if (reg.test(value)) {
              callback()
            } else {
              return callback(new Error('请输入正确的电话'))
            }
          }
        }
      }
    }
    return {
      peopleForm: {
        caseId: 0,
        name: '',
        personType: '',
        cardType: '',
        idCard: '',
        phone: '',
        address: '',
        remark: '',
        photoPath: '',
        idCardFrontPhotoPath: '',
        idCardBackPhotoPath: ''
      },
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      hideUploadIcon: false,
      formRules: {
        name: [
          {
            required: true,
            message: '当事人姓名不能为空',
            trigger: 'blur'
          }
        ],
        personType: [
          {
            required: true,
            message: '当事人类型不能为空',
            trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true,
            // message: '联系电话不能为空',
            trigger: 'blur',
            validator: numberRules.testPhone('请输入正确的身份证号')
          }
        ],
        idCard: [
          {
            required: true,
            // message: '身份证号不能为空',
            trigger: 'blur',
            validator: numberRules.testIdCard('请输入正确的身份证号')
          }
        ],
        cardType: [
          {
            required: true,
            message: '证件类型不能为空',
            trigger: 'blur'
          }
        ]
        // personType: [
        //   {
        //     required: true,
        //     message: '当事人类型不能为空',
        //     trigger: 'blur'
        //   }
        // ]
      },
      caseData: [],
      cardTypeList: []
    }
  },
  computed: {},
  watch: {},
  created () {
    this.initialization()
  },
  methods: {
    initialization () {
      getDictionaryByTypeCode({code:'PERSONTYPE',status:0}).then(res => {
        this.caseData = res
      })
      getDictionaryByTypeCode({code:'CARDTYPE',status:0}).then(res => {
        this.cardTypeList = res
      })
    },
    handleRemove (file) {
      console.log(file)
    },
    peopleIncreaseSaveBtn () {
      this.peopleForm.caseId = this.CaseId
      this.$refs.peopleForm.validate((valid) => {
        if (valid) {
          const t = this
          addPerson(this.peopleForm).then((res) => {
            if (res.success) {
              t.$message({
                message: '保存成功',
                type: 'success'
              })
              this.$emit('dialogChanged', false)
              this.$refs.peopleForm.resetFields()
            } else {
              t.$message({
                message: '保存成功',
                type: 'success'
              })
              this.$emit('dialogChanged', false)
              this.$refs.peopleForm.resetFields()
            }
          }).catch(res => {
            console.log(res)
          })
          // this.$emit('dialogChanged', false)
          // this.$refs.peopleForm.resetFields()
        }
      })
    },
    // 文件提交
    uploadImage (param) {
      const formData = new FormData()
      console.log(param, '1345')
      formData.append('file', param.file) // 提交给后台的文件
      upload(formData).then((res) => {
        console.log(res, '121212')
        if (res.success) {
          this.peopleForm.photoPath = res.data
          console.log(this.peopleForm)
        }
      })
    },
    // 文件提交
    uploadImageFront (param) {
      const formData = new FormData()
      console.log(param, '1345')
      formData.append('file', param.file) // 提交给后台的文件
      upload(formData).then((res) => {
        console.log(res, '121212')
        if (res.success) {
          this.peopleForm.idCardFrontPhotoPath = res.data
          console.log(this.peopleForm)
        }
      })
    },
    // 文件提交
    uploadImageBack (param) {
      const formData = new FormData()
      console.log(param, '1345')
      formData.append('file', param.file) // 提交给后台的文件
      upload(formData).then((res) => {
        console.log(res, '121212')
        if (res.success) {
          this.peopleForm.idCardBackPhotoPath = res.data
          console.log(this.peopleForm)
        }
      })
    }
  }
}
</script>

<style scoped>
.select-type {
  display: flex !important;
}

.peoPic {
  display: flex;
  justify-content: space-between;
}
</style>
