// 文章相关请求模块
import request from '@/utils/request'
// 根据案件会议Id获取案件会议
export const getPersonMeeting = (caseId,personId) => {
  return request({
    method: 'GET',
    url: `/api/PersonMeeting/${caseId}?personId=${personId}`,
  })
}

// 根据案件Id和当事人Id获取当事人会议
export const addPersonMeeting = data => {
  return request({
    method: 'POST',
    url: '/api/PersonMeeting/add',
    data
  })
}

// 编辑当事人会议
export const editPersonMeeting = data => {
  return request({
    method: 'PUT',
    url: '/api/PersonMeeting/edit',
    data
  })
}



