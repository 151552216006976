<template>
    <div>
      <el-form ref="detailForm" label-width="40px">
        <el-row>
          <el-col class="text-align-r">
            <el-button type="warning" icon="el-icon-download" v-if="caseSignFlow.FlowStatus == 2" @click="caseSignFlowDownload">下载已签署协议</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-table :data="tableData" border>
        <el-table-column property="signerName" v-model="tableData.signerName" align="center" label="当事人姓名">
        </el-table-column>
        <el-table-column property="signStatus" v-model="tableData.signStatus" align="center" label="签署状态">
          <template slot-scope="scope">
              <el-tag v-if="scope.row.signStatus !== 2" type="info">待签章</el-tag>
              <el-tag v-if="scope.row.signStatus === 2" type="success">已签章</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
</template>

<script>
import { caseSignQuery } from '@/api/caseSign'
import { flowSignerGet,flowDocumentDownLoad } from '@/api/signFlow'

export default {
  name:'agreementSignDetail',
  props: ['caseId'],
  data() {
      return {
        loading: false,
        caseSignFlow:'',
        tableData: [],
      };
  },
  created() {
  },
  mounted() {
      this.initTemplate()
  },
  methods: {
      initTemplate(){
        caseSignQuery({
            CaseId:this.caseId,
            IncludeDetails:true
        }).then(res => {
            if(res.data){
              console.log(res.data[res.data.length-1])
              this.caseSignFlow = res.data[res.data.length-1]
              flowSignerGet(this.caseSignFlow.FlowId).then(res => {
                console.log('res',res)
                this.tableData = res.signers
              })
            }
        })
      },
      caseSignFlowDownload(){
        flowDocumentDownLoad(this.caseSignFlow.FlowId).then(res => {
          console.log(res)
          res.docs.forEach(doc => {
            window.open(doc.fileUrl)
          });
        })
    },
  }
};
</script>

<style scoped>
.filter-card {
  margin-bottom: 30px;
}

.list-table {
  margin-bottom: 20px;
}

.article-cover {
  width: 60px;
  background-size: cover;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

.increaseForm {
  text-align: center;
}

.el-select .el-input {
  width: 130px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.sf-breadcrumb {
  padding-bottom: 13px;
  border-bottom: #cbddff 1px solid;
  margin-bottom: 20px;
}

.sf-breadcrumb .el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  font-weight: normal !important;
}

.text-align-r {
  text-align: right;
  margin-bottom: 10px;
}

.sf-search-more {
  border: #d8e3f8 1px solid;
  border-radius: 4px;
  padding: 20px 20px 0 20px;
  margin-top: 20px;
  background: #e7effd;
}
</style>
