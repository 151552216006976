
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './styles/style.css'
import './common/font/font.css'

// 加载全局样式文件
// import './styles/index.less'

Vue.use(ElementUI)
Vue.config.productionTip = false
window.console.log(process.env.VUE_APP_API_URL)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
