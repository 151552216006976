<template>
    <div>
        <div v-if="signProcessIndex==0">
            <el-form label-width="120px" size="mini">
                <el-form-item label="请选择签署人员" prop="id">
                    <el-checkbox-group class="check-type" v-for="item in signPersonList" v-model="personIds" :key="item.PersonId">
                        <el-checkbox :label="item.PersonId" >{{item.Name}}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
        </div>
        <div v-if="signProcessIndex==1" v-loading="loading" element-loading-text="签章部署中...请稍等">
            <el-upload
            class="upload-demo"
            drag
            :action="`/sign/api/FileManagement/upload/${signFlowId}?convert2Pdf=true`"
            :data="{flowId: signFlowId}"
            :on-success="signFileUpSuccess"
            :on-remove="signFileRemove"
            :before-upload="beforeSignFileUp"
            :limit = "1">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
        </div>
        <div style="text-align: right">
            <el-button type="primary" v-if="signProcessIndex==0" @click="nextStep">下一步</el-button>
            <el-button type="primary" v-if="signProcessIndex==1" @click="signStart">提交</el-button>
        </div>
    </div>
</template>

<script>
import { signPersonList } from '@/api/personConcerned'
import { getCaseId } from '@/api/case'
import { getPersonAccountById, addPersonAccount } from '@/api/personAccount'
import { caseSignGet,caseSignCreate,caseSignStart,caseSignQuery,caseSignEnd,caseSignRevoke } from '@/api/caseSign'
import { flowDocumentAdd,fileManagementQuery,flowSignFieldAddPlatformAutoSignField,flowSignFieldAddPersonManualSignField,signFlowVoucher,signFlowQuery,flowDocumentDownLoad } from '@/api/signFlow'

export default {
  name:'agreementSign',
  props: ['caseId'],
  data() {
      return {
        case: '',
        signPersonList:[],
        personIds:[],
        signProcessIndex : -1,
        signFlowId:'',
        loading: false,
        signFile: {
          fileId: '',
          fileName: '',
          page:0,
        },
      };
  },
  created() {

  },
  mounted() {
      this.initTemplate()
  },
  methods: {
      async initTemplate(){
        await signPersonList(this.caseId).then(res => {
          console.log('res',res)
            this.signPersonList = res
            res.forEach(arr => {
              getPersonAccountById(arr.IDCard).then(res => {
                if(!res){
                  addPersonAccount({
                  name: arr.Name,
                  idNumber: arr.IDCard,
                  mobile: arr.Phone,
                  email: ''
                  }).then(res => {
                    arr.accountId = res.accountId
                  })
                }else{
                  arr.accountId = res.accountId
                }
              })
              this.personIds.push(arr.PersonId)
            })
            // console.log('personIds',this.personIds)
            // console.log('signPersonList',this.signPersonList)
        })
        await getCaseId(this.caseId).then(res => {
          this.case = res
          console.log('case',this.case)
        })
        await caseSignQuery({
            CaseId:this.caseId,
            IncludeDetails:true
        }).then(res => {
            if(res.data){
              let caseSign = res.data[res.data.length-1]
              console.log('caseSign',caseSign)
              this.signFlowId = caseSign.FlowId
              if(res.data[res.data.length-1].FlowStatus == 0){
                this.personIds = []
                for(var i=0;i<caseSign.Details.length;i++){
                  this.personIds.push(caseSign.Details[i].PersonId)
                }
                this.signProcessIndex = 1
              }else{
                this.signProcessIndex = 0
              }
            }else{
                this.signProcessIndex = 0
            }
        })
      },
      async nextStep(){
          if(this.personIds.length == 0){
            this.$message({
            message: '至少选择一个签署人员',
            type: 'info'
            })
            return
          }
          let res = await caseSignCreate({
            "id": this.caseId,
            "autoArchive": true,
            "businessScene": this.case.Name + '协议签署',
            "personIds": this.personIds,
            'platform': 'RA'
          }).then(res => {
            this.initTemplate()
            this.signProcessIndex = 1
          })
          if(!res){
            this.initTemplate()
            if(this.signFlowId){
              this.signProcessIndex = 1
            }
          }
      },
      async signStart() {
        this.signPersonList = this.signPersonList.filter( item => this.personIds.includes(item.PersonId)) 
        console.log('personIds',this.personIds)
        console.log('signPersonList',this.signPersonList)
        let res = await flowDocumentAdd({
          flowId : this.signFlowId,
          fileId: this.signFile.fileId,
          fileName: this.signFile.fileName
        })
        if(!res){
          this.$message({
            message: '请先上传待签署文件',
            type: 'info'
          })
          return
        }
        for(var i=0;i<this.signPersonList.length;i++){
          let yNum = Number(Math.floor((i)/4))+1
          let xNum = Number(i % 4)
          const result = await flowSignFieldAddPersonManualSignField({
            flowId: this.signFlowId,
            accountId: this.signPersonList[i].accountId,
            fileId: this.signFile.fileId,
            page: this.signFile.page,
            x: 490 - (120 * xNum),
            y: 140 * yNum - 40,
            sealType:0,
            thirdOrderNo: this.signFlowId+'_'+this.signFile.fileId
          })
          
          if(i==this.signPersonList.length-1 && result){
            console.log('设置个人用户签署区返回',result)
            caseSignStart({
              flowId: this.signFlowId,
            }).then(res => {
              console.log('流程开启返回',res)
              this.$message({
                message: '签署文件已部署，请等待文件签署完成！',
                type: 'success'
              })
              this.$emit('dialogChanged',false)
            })
          }
        }
      },
       // 上传文件之前
      beforeSignFileUp(file) {
        let size20M = file.size / 1024 / 1024 < 20
        if (!size20M) {
          this.$message.error('上传文件大小不能超过 20MB!');
          return false
        }
        const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
        const whiteList = ["docx", "doc"];
        if (whiteList.indexOf(fileSuffix) === -1) {
          this.$message.error('上传文件只能是docx,doc格式!');
          return false;
        }
      },
      signFileUpSuccess (data) {
        const that = this;
        this.loading=true
        const interval = setInterval(function(){
          fileManagementQuery(data.fileId).then((res) => {
            if(res.status == 5){
              that.signFile.fileId = res.fileId
              that.signFile.fileName = res.name
              that.signFile.page = res.pdfTotalPages
              that.loading=false
              clearInterval(interval)
            }
          })
        },1000)
    },
    signFileRemove(){
        this.signFile.fileId = ''
        this.signFile.fileName = ''
        this.signFile.page = 0
    },
  }
};
</script>

<style scoped>
    .check-type {
        padding-right: 20px !important;
        display: table-cell;
        justify-content: start;
        white-space: pre-line;
        word-wrap: break-word;
    }
</style>
