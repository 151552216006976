<template>
	<div class="home-container">
		<el-header class="sf-top">
			<el-row>
				<el-col :span="12">
					<div class="sf-date">{{nowtime}}</div>
				</el-col>
				<el-col :span="12">
					<div class="sf-user">
						<!-- <sf-user></sf-user> -->
					</div>
				</el-col>
			</el-row>
		</el-header>
		<div class="logo">
			<img src="../../assets/sflogo.png">
		</div>
		<ul class="sf-nav">
			<li class="sf-nav-active">
				<router-link to="login">远程援助</router-link>
			</li>
			<li>
				<router-link to="home">远程咨询</router-link>
			</li>
			<li>
				<router-link to="home">远程调解</router-link>
			</li>
			<li>
				<router-link to="home">远程复议</router-link>
			</li>
			<li>
				<router-link to="home">远程仲裁</router-link>
			</li>
			<li>
				<router-link to="home">远程公证</router-link>
			</li>
			<li>
				<router-link to="home">远程鉴定</router-link>
			</li>
		</ul>
		<footer class="footer">
			主办单位：舟山市司法局
		</footer>
	</div>
</template>

<script>
	import sfUser from '../layout/components/user.vue'
	import moment from "moment"

	export default {
		name: 'home',
		components: {
			sfUser
		},
		data(){
			return {
				nowtime:''
			}
		},
		mounted() {
			moment.locale('zh-cn');
			this.nowtime = moment().format('LL dddd')
			console.log(this.nowtime)
		},
	}
</script>

<style>
	.home-container {
		font-size: 16px;
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 60px;
	}
	.sf-top {
		width: 100%;
		height: 90px;
		background-image: url(../../assets/topbg.png);
		background-position: 50%;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
	}

	.sf-date {
		color: #fff;
		padding-left: 20px;
		font-size: 14px;
		letter-spacing: 1px;
		padding-top: 20px;
	}

	.sf-user {
		float: right;
		padding: 20px 0 0 0;
	}

	.logo img {
		height: 200px;
	}

	.footer {
		position: fixed;
		bottom: 0;
		width: 100%;
		height: 42px;
		color: #FFFFFF;
		background-image: url(../../assets/footerbg.png);
		padding-top: 20px;
		text-align: center;
		font-size: 14px;
		opacity: 0.8;
		letter-spacing: 1px;
	}

	.sf-nav {
		width: 950px;
		margin-top: 0px;
	}

	.sf-nav li,
	.sf-nav li+li+li+li+li+li {
		list-style-type: none;
		float: left;
		width: 210px;
		height: 160px;
		background-color: #37a0e6;
		margin: 8px;
	}

	.sf-nav li+li {
		background-color: #d26f2f;
	}

	.sf-nav li+li+li,
	.sf-nav li+li+li+li+li {
		background-color: #9b47c4;
	}

	.sf-nav li+li+li+li,
	.sf-nav li+li+li+li+li+li+li {
		background-color: #1fad95;
	}

	.sf-nav li a {
		box-sizing: border-box;
		display: block;
		width: 210px;
		height: 160px;
		line-height: 160px;
		text-align: center;
		color: #fff;
		text-decoration: none;
		font-size: 22px;
		font-weight: 600;
		letter-spacing: 2px;
		padding-top: 33px;
		background-repeat: no-repeat;
		background-position: 50% 26%;
		background-size: 70px 70px;
		background-image: url(../../assets/icon2.png);
	}

	.sf-nav li+li+li a {
		background-repeat: no-repeat;
		background-position: 50% 26%;
		background-size: 70px 70px;
		background-image: url(../../assets/icon3.png);
	}

	.sf-nav li+li+li+li a {
		background-repeat: no-repeat;
		background-position: 50% 26%;
		background-size: 70px 70px;
		background-image: url(../../assets/icon4.png);
	}

	.sf-nav li+li+li+li+li a {
		background-repeat: no-repeat;
		background-position: 50% 26%;
		background-size: 70px 70px;
		background-image: url(../../assets/icon5.png);
	}

	.sf-nav li+li+li+li+li+li a {
		background-repeat: no-repeat;
		background-position: 50% 26%;
		background-size: 70px 70px;
		background-image: url(../../assets/icon6.png);
	}

	.sf-nav li+li+li+li+li+li+li a {
		background-repeat: no-repeat;
		background-position: 50% 26%;
		background-size: 70px 70px;
		background-image: url(../../assets/icon7.png);
	}

	.sf-nav li a:hover {
		background: #4db5fb;
		background-repeat: no-repeat;
		background-position: 30% 50%;
		background-size: 70px 70px;
		background-image: url(../../assets/icon1.png);
	}

	.sf-nav li+li a:hover {
		background: #ff7a24;
		background-repeat: no-repeat;
		background-position: 50% 26%;
		background-size: 70px 70px;
		background-image: url(../../assets/icon2.png);
	}

	.sf-nav li+li+li a:hover {
		background: #ac2fe9;
		background-repeat: no-repeat;
		background-position: 50% 26%;
		background-size: 70px 70px;
		background-image: url(../../assets/icon3.png);
	}

	.sf-nav li+li+li+li a:hover {
		background: #0cd2b0;
		background-repeat: no-repeat;
		background-position: 50% 26%;
		background-size: 70px 70px;
		background-image: url(../../assets/icon4.png);
	}

	.sf-nav li+li+li+li+li a:hover {
		background: #ac2fe9;
		background-repeat: no-repeat;
		background-position: 50% 26%;
		background-size: 70px 70px;
		background-image: url(../../assets/icon5.png);
	}

	.sf-nav li+li+li+li+li+li a:hover {
		background: #4db5fb;
		background-repeat: no-repeat;
		background-position: 50% 26%;
		background-size: 70px 70px;
		background-image: url(../../assets/icon6.png);
	}

	.sf-nav li+li+li+li+li+li+li a:hover {
		background: #0cd2b0;
		background-repeat: no-repeat;
		background-position: 50% 26%;
		background-size: 70px 70px;
		background-image: url(../../assets/icon7.png);
	}

	.sf-nav-active,
	.sf-nav-active a:link,
	.sf-nav-active a:hover {
		width: 436px !important;
		padding-top: 0;
		background-image: url(../../assets/icon1.png);
		background-repeat: no-repeat;
		background-position: 30% 50%;
		background-size: 70px 70px;
	}

	.sf-nav-active a:link,
	.sf-nav-active a:hover {
		padding-left: 60px;

	}
</style>
