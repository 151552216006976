<template>


  <div class="article-container sf" style="height:100%" v-loading="nextButtonLoading" element-loading-text="保存中...请稍等">
    <!-- <div id="light" class="white_content" v-show="dialogShow && userdata.InquiryStatus == 3"
    @mousedown="down"
    @touchstart="down"
    @mousemove="move"
    @touchmove="move"
    @mouseup="end"
    @touchend="end"
    ref="fu">
        <iframe src="https://admin.zsportservice.com/nls/index" frameborder="0" class="pc iframe" scrolling="auto" allow='microphone;camera;midi;encrypted-media;' height="80%" width="100%"></iframe>
    </div>  -->
    <div slot="header" class="clearfix sf-breadcrumb">
      <!--面包屑-->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>文件模板</el-breadcrumb-item>
        <el-breadcrumb-item>案件详情</el-breadcrumb-item>
        <el-breadcrumb-item>问前准备</el-breadcrumb-item>
      </el-breadcrumb>
      <!--/面包屑-->
    </div>
    <el-card class="filter-card">

      <!--数据表格筛选-->
      <el-form class="btn-top" ref="form" label-width="40px">
        <div style="float:left">
          <div style="float:left;margin-left: 10px" class="btn-button" v-for="item in inquiryList" :key="item.TemplateId">
            <el-button @click="templatebtn(item)" :type="selectId == item.QuestionConfigId ? 'primary' : '' ">{{item.DocType}}</el-button>
          </div>
          <!-- <div style="float:left;margin-left: 10px">
          </div> -->
        </div>
        <div></div>
        <!--/数据表格筛选-->
        <div style="float:right">
          <el-button-group style="margin-right: 10px">
            <!-- <el-button @click="dialogInquiryVisible = true" class="el-icon-edit-outline"></el-button> -->
            <!-- <el-button @click="deleteBtn" class="el-icon-delete"></el-button> -->
          </el-button-group>
            <span style="color:red;margin-right: 20px;" >
              会议室号：{{meetinginfo.MeetingNumber}}
            </span>
            <span style="color:red;margin-right: 20px;" >
              密码：{{meetinginfo.Password}}
            </span>
            <span style="color:red;margin-right: 20px;" >
              控制密码：{{meetinginfo.ControlPassword}}
            </span>
            <el-button class="el-icon-back" @click="back">返回</el-button>

          <!-- <el-button type="warning" class="el-icon-s-check" @click="ready">在线签章</el-button> -->
        </div>
      </el-form>
      <!--/数据表格筛选-->

      <!-- <div class="template-header">
        <h2>{{title}}</h2>
      </div>
      <div class="template-body">
        <div class="box2">
          <span>{{template}}</span>
          <div>
            <el-tiptap
              v-model="questionContent"
              :extensions="extensions"
              height="350"
              lang="zh"
              placeholder="请输入内容"
            ></el-tiptap>
          </div>
        </div>
      </div> -->
      <iframe id="wordOnlineIframe" :src="wordOnlineUrl" frameborder="0" class="pc iframe" scrolling="auto" style="width: 100%;height:650px"></iframe>

      <!--按钮-->
      <div class="btn-bottom">
        <el-button type="warning" @click="nextButton" v-if="userdata.InquiryStatus == 3">询问完毕</el-button>
        <el-button type="warning" @click="ready" v-if="(userdata.InquiryStatus == 4 || userdata.InquiryStatus == 5 || userdata.InquiryStatus == 9) && (!signFlow || signFlow.flowStatus == 0 || signFlow.flowStatus == 3)">在线签章</el-button>
        <el-button type="warning" @click="signFlowEnd" v-if="signFlow.flowStatus == 1">签章撤回</el-button>
        <el-button type="warning" @click="voucherDownload" v-if="signFlow.flowStatus == 2">签署文件下载</el-button>
        <el-button type="warning" @click="downloadWord" v-if="(userdata.InquiryStatus == 4 || userdata.InquiryStatus == 5 || userdata.InquiryStatus == 9)">文档下载</el-button>
        <!-- <el-button type="primary" @click="openDialog" v-if="dialogShow == false && userdata.InquiryStatus == 3">显示语音转录</el-button> -->
        <!-- <el-button type="primary" @click="closeDialog" v-if="dialogShow == true && userdata.InquiryStatus == 3">最小化语音转录</el-button> -->
        <el-button type="primary" @click="openDialog2" v-if="dialogShow == true && userdata.InquiryStatus == 3">打开语音转录</el-button>
        <el-button type="primary" @click="openScreen">全屏显示</el-button>
      </div>
      <!--/按钮-->
    </el-card>
    <el-dialog class="increaseForm" v-if="dialogInquiryVisible"  title="配置询问事项" append-to-body :visible.sync="dialogInquiryVisible"
               :close-on-click-modal="false">
      <inquiry :dialogInquiryVisible="dialogInquiryVisible" :PersonId="personId"
               @editChanged="editDataChange($event)"/>
    </el-dialog>
    <!-- 签署标题 -->
    <el-dialog class="increaseForm" title="签署" append-to-body :visible.sync="signTitleShow"
               :close-on-click-modal="false"  v-loading="loading" element-loading-text="签章部署中...请稍等">
      <div class="sign-form-item" v-if="signProcessIndex==0">
        <div>签署标题</div>
        <div><el-input placeholder="请输入签署标题" v-model="signTitle"></el-input></div>
      </div>
      <div class="" v-if="signProcessIndex==1">
      <!-- <div class="sign-left">是否需要平台自动签章:</div>
      <el-select class="select-type sign-left-select" v-model="platformAutograph" placeholder="请选择是否需要设置平台自动签署区">
        <el-option label="是" value="true"></el-option>
        <el-option label="否" value="false"></el-option>
      </el-select> -->
        <InquirySelf :PersonId.Number="personId" @fileSelected="fileSelected($event)" style="margin-top: 20px;"/>
                <div>
<!-- <el-upload
          class="upload-demo"
          drag
          :action="`/sign/api/FileManagement/upload/${signFlowId}?convert2Pdf=true`"
          :data="{flowId: signFlowId}"
          :on-success="signFileUpSuccess"
          :on-remove="signFileRemove"
          multiple>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload> -->
                </div>
        
                <!-- <el-upload
          class="upload-demo"
          drag
          :action="`/sign/api/FileManagement/upload/${signFlowId}`"
          :data="{flowId: signFlowId}"
          :on-success="signFileUpSuccess"
          accept="application/pdf"
          multiple>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只能上传pdf文件</div>
        </el-upload> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="signTitleShow = false">取 消</el-button>
        <el-button type="primary" @click="signTitleNetx" v-if="signProcessIndex!=1">下一步</el-button>
        <el-button type="primary" @click="signUpload" v-if="signProcessIndex==1">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {Constants} from "@constants";
import InquiryEdit from './components/inquiry-edit'
import { getList, editReady, editCase, getCaseId, deleteQuestionConfig } from '@/api/case'

import { getPersonId,completeInquiry } from '@/api/personConcerned'
import { signCreate,signStart,signQuery,signEnd,signRevoke,signUpload } from '@/api/personConcernedSign'
import { getPersonAccountById, addPersonAccount } from '@/api/personAccount'
import { flowDocumentAddList,fileManagementQuery,flowSignFieldAddPlatformAutoSignField,flowSignFieldAddPersonManualSignField,signFlowVoucher,signFlowQuery,flowDocumentDownLoad } from '@/api/signFlow'
import Inquiry from './components/inquiry'
import InquirySelf from './components/inquiry-self'
import { getWordOnlineUrl,downloadWordOnline } from '@/api/wordOnline'
import { getPersonMeeting } from '@/api/personMeeting'

export default {
  name: 'Template',
  components: {
    InquiryEdit, getList, Inquiry,InquirySelf
  },
  props: {},
  data () {
    return {
      loading:false,
      nextButtonLoading:false,
      template: '',
      title: '',
      selectId: -1,
      flag: false,
      input1: '',
      input2: '',
      input3: '',
      select: '',
      dialogFormVisible: false,
      personId: 0,
      inquiryList: [],
      dialogInquiryVisible: false,
      questionContent: '',
      fileId:'',
      wordOnlineUrl:'', //在线word读取地址
      userdata: {},
      inquiryStatusFlag:false,
      signTitleShow: false,
      signTitle: '',
      signProcessIndex: 0,
      signAccountId: '',
      signFlowId: '',
      signFlow: '',
      signFileIds: [],
      signFileIdList: [],
      convertToPdfInterval:'',
      dialogShow:false,
      platformAutograph:'false',
      flags: false, //控制使用
      position: {
        x: 0,
        y: 0,
      },
      nx: "",
      ny: "",
      dx: "",
      dy: "",
      xPum: "",
      yPum: "",
      meetinginfo:''
    }
  },
  computed: {},
  watch: {
    async signProcessIndex (val)/* 监听流程进行 */ {
      if (val == 1) {
        var res;
          res = await signCreate({
            id:this.userdata.PersonId,
            autoArchive:true,
            businessScene: this.signTitle
          })
          console.log('sign创建返回res',res)
          if(res){
            this.initSignFlow()
          }
      }
    },
    loading(val){
      if(val == false){
        clearInterval(this.convertToPdfInterval)
      }
    }
  },
  created () {
  },
  mounted () {
    this.initialization()
    getPersonMeeting(this.$route.query.CaseId,this.$route.query.PersonId).then(res => {
        if (res) {
          this.meetinginfo = res
        }
      })
  },
  methods: {
    async initialization () {
      this.personId = this.$route.query.PersonId
      this.userdata = await getPersonId(this.personId)
      console.log('userdata',this.userdata)
      this.inquiryStatusFlag = (this.userdata.InquiryStatus != 4 && this.userdata.InquiryStatus != 5 && this.userdata.InquiryStatus != 8 && this.userdata.InquiryStatus != 9)

      this.callGetList(true)

      this.initSignFlow()
      this.dialogShow = true
    },
    async initSignFlow(){
      const res = await signQuery({
            CaseId:this.userdata.CaseId,
            PersonId:this.userdata.PersonId
      })
      if(res.data){
        console.log('signFlowList',res.data)
        this.signFlowId = res.data[res.data.length-1].FlowId
        this.signFlow = await signFlowQuery(this.signFlowId)
        console.log('signFlow',this.signFlow)
        console.log('signFlowId',this.signFlowId)
      }
    },
    templatebtn (val) {
      this.selectId = val.QuestionConfigId
      this.fileId = val.FileId
      getWordOnlineUrl(this.fileId,this.inquiryStatusFlag).then((res) => {
        console.log('getWordOnlineUrl',res)
        if (res) {
          // let url = res.Url.replace("oos","fw").replace("/we/","/wosedit/").replace("/wv/","/wosview/")
          this.wordOnlineUrl = res.Url
        }
      })
      this.template = val.TemplateName
      this.questionContent = val.QuestionContent
    },
    back () {
      this.$router.push('/case/detail?CaseId=' + this.$route.query.CaseId + '&&PersonId=' + this.$route.query.PersonId)
    },
    editDataChange (params) {
      this.callGetList()
      this.dialogInquiryVisible = params
    },
    downloadWord(){
      downloadWordOnline(this.fileId)
    },
    // 准备完毕
    async ready () {
      try {
        let resById = await getPersonAccountById(this.userdata.IDCard)
        if (!resById) {
          resById = await addPersonAccount({
            name: this.userdata.Name,
            idNumber: this.userdata.IDCard,
            mobile: this.userdata.Phone,
            email: ''
          })
        }
        this.signAccountId = resById.accountId
        if(this.signFlow.flowStatus == 0 || this.signFlow.flowStatus == 1){
          this.signProcessIndex = 1
        }
        this.signTitleShow = true
      } catch (error) {
        console.log(error)
      }
      /* await this.questionContentChange()
      await this.edit() */
    },
    async signFlowEnd(){
        // await signEnd({
        //     flowId: this.signFlowId
        // })
        let _this = this
        let currentUserInfo = Constants.getSessionStorage(Constants.get("UserInfo"), true);
        await signRevoke({
            flowId: _this.signFlowId,
            operatorId: currentUserInfo.UserId,
            revokeReason: '流程正常撤回'
        }).then(res => {
          console.log('流程正常撤回',res)
          _this.initialization()
          _this.signProcessIndex = 0
          _this.signFileIdList = []
        })
    },
    async voucherDownload(){
      // const res = await signFlowVoucher(this.signFlowId)
      const res = await flowDocumentDownLoad(this.signFlowId)
      console.log(res.docs)
      res.docs.forEach((doc,index) => {
        const path = doc.fileUrl
        var timer1 = setTimeout(function(path){
          return function(){
            window.open(path)
            clearTimeout(timer1);
          }
        }(path), 1000 * index)
      });
    },
    async signTitleNetx ()/* 流程并下一步 */ {
      this.signProcessIndex++ // 签署流程 1
    },
    signFileUpSuccess (data) {
      const that = this;
      this.loading=true
      this.convertToPdfInterval = setInterval(function(){
        fileManagementQuery(data.fileId).then((res) => {
          if(res.status == 5){
              that.signFileIdList.push({
                fileId: res.fileId,
                fileName: res.name,
                page:res.pdfTotalPages,
              })
              that.loading=false
          }
        })
      },1000)
    },
    signFileRemove(data,files){
        console.log(this.signFileIdList)
        console.log(data.response.fileId)
        for(var i=0;i<this.signFileIdList.length;i++){
          if(this.signFileIdList[i].fileId == data.response.fileId){
              this.signFileIdList.splice(i,1)
          }
        }
    },
    async fileSelected(event){
        this.signFileIds = event
    },
    async signUpload(){
      let _this = this
      if(_this.platformAutograph == ''){
          _this.$message({
            message: '请先选择是否需要平台自动签章',
            type: 'info'
          })
          return
      }
      let fielIds = _this.signFileIds
      if(fielIds.length == 0){
          _this.$message({
            message: '必须选择至少一份需要签署的文章',
            type: 'info'
          })
          return
      }
       _this.loading=true
      console.log(_this.signFileIds)
      let resFileIds = []
      for(var i=0;i<_this.signFileIds.length;i++){
        const result =await signUpload({
          flowId: _this.signFlowId,
          fileId: _this.signFileIds[i]
        })
        if(result){
            resFileIds.push(result.data)
            if(i==_this.signFileIds.length-1){
              // this.signFileUpSuccess2(resFileIds)
              // const that = this
              let booleans = []
              for(var i=0;i<resFileIds.length;i++){
                booleans.push(false)
              }
              for(var i=0;i<resFileIds.length;i++){
                let fileId = resFileIds[i]
                const indexs = i
                let convertToPdfInterval = setInterval(function(){
                  fileManagementQuery(fileId).then((res) => {
                    if(res.status == 5){
                        clearInterval(convertToPdfInterval)
                        _this.signFileIdList.push({
                          fileId: res.fileId,
                          fileName: res.name,
                          page:res.pdfTotalPages,
                        })
                        booleans[indexs]=true
                        if(booleans.findIndex(target=>target===false) == -1){
                          _this.signStart()
                        }
                    }
                  })
                },1000)
              }
            }
        }
      }
    },
    async signStart () {
        //多个流程文档添加
        let res = await flowDocumentAddList({
            flowId: this.signFlowId,
            docs: this.signFileIdList,
        })
        if(!res){
          this.$message({
            message: '请先上传待签署文件',
            type: 'info'
          })
          return
        }else{
          console.log(this.platformAutograph)
          if(this.platformAutograph == 'true'){
            // 添加平台自动签署区
            for(var i=0;i<this.signFileIdList.length;i++){
                await flowSignFieldAddPlatformAutoSignField({
                  flowId: this.signFlowId,
                  fileId: this.signFileIdList[i].fileId,
                  page: this.signFileIdList[i].page,
                  x: 200,
                  y: 50,
                  thirdOrderNo: this.signFlowId+'_'+this.signFileIdList[i].fileId
                })
            }
          }
          //添加个人签署区
          for(var i=0;i<this.signFileIdList.length;i++){
              const result = await flowSignFieldAddPersonManualSignField({
                flowId: this.signFlowId,
                accountId: this.signAccountId,
                fileId: this.signFileIdList[i].fileId,
                page: this.signFileIdList[i].page,
                x: 400,
                y: 60,
                sealType:0,
                thirdOrderNo: this.signFlowId+'_'+this.signFileIdList[i].fileId
              })
              if(i==this.signFileIdList.length-1 && result){
                  res = await signStart({
                    flowId: this.signFlowId,
                  })
                  console.log('流程开启',res)
                  this.$message({
                    message: '签署文件已部署，请等待文件签署完成！',
                    type: 'success'
                  })
                  this.initSignFlow()
                  this.userdata = await getPersonId(this.personId)              
                  this.callGetList()
                  this.loading = false
                  this.signTitleShow = false
              }
          }
        }

    },
    edit () {
      const search = {
        id: this.personId,
        items: this.inquiryList
      }
      editReady(search).then(res => {
        this.$message({
          message: '恭喜你，保存成功！',
          type: 'success'
        })
        this.flag = true
      })
    },
    // 内容编辑
    questionContentChange () {
      for (const i in this.inquiryList) {
        if (this.inquiryList[i].QuestionConfigId == this.selectId) {
          this.inquiryList[i].QuestionContent = this.questionContent
        }
      }
    },
    callGetList (val) {
      getList(this.personId).then(res => {
        this.inquiryList = res
        if (val) {
          this.templatebtn(this.inquiryList[0])
        }
      })
    },
    // 删除
    callDelete () {
      if (this.selectId == -1) {
        this.$message({
          type: 'info',
          message: '未选择'
        })
      } else {
        deleteQuestionConfig(this.selectId).then(res => {
          this.selectId = -1
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.callGetList(true)
        })
      }
    },
    loadArticles (page = 1) {
    },
    nextButton () {
      let _this = this
      _this.$confirm('案件是否问询完毕', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _this.nextButtonLoading = true
          setTimeout(function(){
          _this.transferred()
          _this.nextButtonLoading = false
          },5000)
        }).catch(() => {
        })
      
    },
    // 办结
    async transferred () {
        const res = await completeInquiry({id:this.userdata.PersonId})
        this.$message({
          type: 'success',
          message: '问询已完毕，请开始设置签章'
        })
        this.userdata = await getPersonId(this.personId)
        this.inquiryStatusFlag = (this.userdata.InquiryStatus != 4 && this.userdata.InquiryStatus != 5 && this.userdata.InquiryStatus != 8 && this.userdata.InquiryStatus != 9)
        this.initSignFlow()
        this.callGetList(true)
      
    },
    deleteBtn () {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.callDelete()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    openScreen(){
      var el = document.getElementById("wordOnlineIframe");
          ( el.requestFullscreen
      || el.webkitRequestFullScreen
      || el.mozRequestFullScreen 
      || el.msRequestFullscreen).call(el)
    },
    openDialog(){
      this.dialogShow = true
    },
    openDialog2(){
      window.open('https://sifa.hilang.com.cn/openRtast')
    },
    closeDialog(){
      this.dialogShow = false
    },
    down() {
  this.flags = true;
  var touch;
  if (event.touches) {
  touch = event.touches[0];
  } else {
  touch = event;
  }
  this.position.x = touch.clientX;
  this.position.y = touch.clientY;
  this.dx = this.$refs.fu.offsetLeft;
  this.dy = this.$refs.fu.offsetTop;
 },
 move() {
  if (this.flags) {
  var touch;
  if (event.touches) {
   touch = event.touches[0];
  } else {
   touch = event;
  }
  this.nx = touch.clientX - this.position.x;
  this.ny = touch.clientY - this.position.y;
  this.xPum = this.dx + this.nx;
  this.yPum = this.dy + this.ny;
  let width = window.innerWidth - this.$refs.fu.offsetWidth; //屏幕宽度减去自身控件宽度
  let height = window.innerHeight - this.$refs.fu.offsetHeight; //屏幕高度减去自身控件高度
  this.xPum < 0 && (this.xPum = 0);
  this.yPum < 0 && (this.yPum = 0);
  this.xPum > width && (this.xPum = width);
  this.yPum > height && (this.yPum = height);
  // if (this.xPum >= 0 && this.yPum >= 0 && this.xPum<= width &&this.yPum<= height) {
  this.$refs.fu.style.left = this.xPum + "px";
  this.$refs.fu.style.top = this.yPum + "px";
  // }
  //阻止页面的滑动默认事件
  document.addEventListener(
   "touchmove",
   function () {
   event.preventDefault();
   },
   false
  );
  }
 },
 //鼠标释放时候的函数
 end() {
  this.flags = false;
 },
//  minimize(){
//       this.white_content_style={
//         width: '300px',
//         height: '300px'
//       }
//  },
//  reduction(){
// this.white_content_style={
//         width: '50%',
//         height: '80%'
//       }
//  }
  }
}
</script>

<style scoped>
.sign-left{
  line-height: 40px;
  float:left;
}
/* .sign-left-select{
  text-align: left;
} */
.white_content { 
            position: absolute; 
            top: 2%; 
            left: 2%; 
            width: 55%; 
            height: 90%; 
            /* padding: 20px; */
            /* border: 1px solid black;  */
            border-top:1px solid black;;
            background:linear-gradient(to right, #1e5858 "+e+", #af2d2d "+e+", #ffffff 100%);
            background-color: white; 
            z-index:1002; 
            overflow: auto; 
        } 
.filter-card {
  margin-bottom: 30px;
}
.btn-top{
  width: 100%;
  float: left;
  /* display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: auto 1fr auto; */
}
.btn-button{
  /* width: auto; */
}

.list-table {
  margin-bottom: 20px;
}

.article-cover {
  width: 60px;
  background-size: cover;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

.increaseForm {
  text-align: center;
}

.el-select .el-input {
  width: 130px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.sf-breadcrumb {
  padding-bottom: 13px;
  border-bottom: #cbddff 1px solid;
  margin-bottom: 20px;
}

.sf-breadcrumb .el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  font-weight: normal !important;
}

.text-align-r {
  text-align: right;
}

.sf-search-more {
  border: #d8e3f8 1px solid;
  border-radius: 4px;
  padding: 20px 20px 0 20px;
  margin-top: 20px;
  background: #e7effd;
}

.sf-template {
  display: flex;
  border: #dcdfe6 1px solid;
  margin: 20px 0;
  padding: 10px;
  overflow-y: scroll;
}

.sf-template ul {
  width: 100%;
  height: 330px;
  padding: 0;
  margin: 0;
}

.sf-template ul li {
  box-sizing: border-box;
  float: left;
  display: flex;
  width: calc(20% - 28px);
  height: 270px;
  margin: 14px;
  border: #e7e9f0 1px solid;
  box-shadow: 3px 3px 3px #eee;
  background-color: #f4f8ff;
}

.sf-template ul li a {
  width: 100%;
  padding: 20px;
  font-size: 24px;
  text-align: center;
  line-height: 38px;
  font-family: 宋体;
  font-weight: bold;
}

.template-header {
  padding: 30px 0 0 0;
  text-align: center;
}

.template-body {
  border: solid 1px #EBEEF5;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.box1, .box2 {
  border: solid 1px #EBEEF5;
  text-align: center;
}

.main-box1 {
  width: 40%;
}

.main-box2 {
  width: 60%;
}

.box1 span, .box2 span {
  height: 30px;
  line-height: 30px;
  background: #eeeeee;
  width: 100%;
  display: inline-flex;
  justify-content: center;
}

.box1 {
  height: 200px;
}

.box2 {
  width: 100%;
  height: 402px;
}

.btn-bottom button{
  margin-left: 10px;
}
.btn-bottom {
  display: flex;
  flex-direction: row-reverse;
}

@media (min-width: 1920px) {
  .sf-template ul li {
    width: calc(16.66% - 28px);
    height: 300px;
  }

  .sf-template ul {
    height: 490px;
  }
}

.el-select .el-input {
  width: 150px;
}

.el-input-group__append {
  background-color: #5486e5 !important;
  color: #fff !important;
  font-size: 22px;
  padding: 0 13px !important;
}

.el-input-group__append:hover {
  background-color: #71a0fa !important;
}

.divcenter{
  width: 100%;
  height: 170px;
  display: grid;
  align-items: center;
  justify-items: center;
}
.inquiry{
  width: 100%;
  height: auto;
  max-height: 150px;
  overflow: auto;
}
.inquiry-piece{
  margin: 1% 0 0 0;
  cursor: pointer;
}
.highlight{
  color: #409eff;
}
.sign-form-item {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 25px;
}
</style>
