<template>
    <div>
      <el-table :data="tableData" border>
        <el-table-column property="thumbnailUrl" v-model="tableData.thumbnailUrl" align="center" label="会议截图">
        <template slot-scope="scope">
              <img :src="scope.row.thumbnailUrl" width="70px" height="50px"/>
          </template>
        </el-table-column>
        <el-table-column property="startTime" v-model="tableData.startTime" align="center" label="开始时间" :formatter="dateFormat">
        </el-table-column>
        <el-table-column property="endTime" v-model="tableData.startTime" align="center" label="结束时间" :formatter="dateFormat">
        </el-table-column>
        <el-table-column property="fileSize" v-model="tableData.fileSize" align="center" label="视频大小">
          <template slot-scope="scope">
            {{Math.floor(scope.row.fileSize/1000/1000)}} M
          </template>
        </el-table-column>
        <el-table-column prop="peoOperate" align="center" label="操作">
          <div class="icon-box" slot-scope="scope">
            <a @click="downloadVideo(scope.row)" class="btn-view"><i class="el-icon-download"></i>视频下载</a>
          </div>
        </el-table-column>
      </el-table>
    </div>
</template>

<script>
import moment from "moment"
import { getVodDownloadUl,vodQuery } from '@/api/video'
import { getPersonMeeting } from '@/api/personMeeting'

export default {
  name:'videoList',
  props: ['caseId','personId'],
  data() {
      return {
        loading: false,
        tableData: [],
        meetingRoomNumbers: []
      };
  },
  created() {
  },
  mounted() {
      this.initTemplate()
  },
  methods: {
      initTemplate(){
        getPersonMeeting(this.caseId,this.personId).then(res => {
          console.log('getPersonMeeting',res)
          this.meetingRoomNumbers.push(res.MeetingNumber)
          vodQuery({
              meetingRoomNumbers: this.meetingRoomNumbers
          }).then(res => {
            this.tableData = res.list
              console.log(res)
          })
        })
      },
      downloadVideo(row){
        getVodDownloadUl(row.vodId).then(res => {
          window.open(res.downloadUrl)
        })
      },
      //时间格式化
      dateFormat(row, column) {  
        var date = row[column.property];  
        if (date == undefined) {  
          return "";  
        }else{
          return moment(date).format("YYYY-MM-DD HH:mm:ss");  
        }
      }

  }
};
</script>

<style scoped>
.filter-card {
  margin-bottom: 30px;
}

.list-table {
  margin-bottom: 20px;
}

.article-cover {
  width: 60px;
  background-size: cover;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

.increaseForm {
  text-align: center;
}

.el-select .el-input {
  width: 130px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.sf-breadcrumb {
  padding-bottom: 13px;
  border-bottom: #cbddff 1px solid;
  margin-bottom: 20px;
}

.sf-breadcrumb .el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  font-weight: normal !important;
}

.text-align-r {
  text-align: right;
}

.sf-search-more {
  border: #d8e3f8 1px solid;
  border-radius: 4px;
  padding: 20px 20px 0 20px;
  margin-top: 20px;
  background: #e7effd;
}
</style>
