// 文章相关请求模块
import request from '@/utils/request'

// 签署流程创建请求
export const signFlowCreate = data => {
  return request({
    method: 'POST',
    url: '/sign/api/SignFlow/Create',
    data
  })
}

//查询文件上传状态
export const fileManagementQueryStatus = fileId => {
  return request({
    method: 'GET',
    url: '/sign/api/FileManagement/QueryStatus?FileId='+fileId
  })
}

//查询PDF文件详情
export const fileManagementQuery = fileId => {
  return request({
    method: 'GET',
    url: '/sign/api/FileManagement/Query?FileId='+fileId
  })
}

// 流程文档添加
export const flowDocumentAdd = data => {
  return request({
    method: 'POST',
    url: '/sign/api/FlowDocument/Add',
    data
  })
}
// 多个流程文档添加
export const flowDocumentAddList = data => {
  return request({
    method: 'POST',
    url: '/sign/api/FlowDocument/AddList',
    data
  })
}

// 平台自动签署区
export const flowSignFieldAddPlatformAutoSignField = data => {
  return request({
    method: 'POST',
    url: '/sign/api/FlowSignField/AddPlatformAutoSignField',
    data
  })
}

// 个人手动签署区
export const flowSignFieldAddPersonManualSignField = data => {
  return request({
    method: 'POST',
    url: '/sign/api/FlowSignField/AddPersonManualSignField',
    data
  })
}

// 开启签署
export const signFlowStart = data => {
  return request({
    method: 'PUT',
    url: '/sign/api/SignFlow/Start',
    data
  })
}

// 签署过程数据存储凭证
export const signFlowVoucher = flowId => {
  return request({
    method: 'GET',
    url: '/sign/api/SignFlow/Voucher?FlowId='+flowId,
  })
}

// 查询签署流程
export const signFlowQuery = flowId => {
  return request({
    method: 'GET',
    url: '/sign/api/SignFlow/Query?FlowId='+flowId,
  })
}

// 流程文档下载
export const flowDocumentDownLoad = flowId => {
  return request({
    method: 'GET',
    url: '/sign/api/FlowDocument/DownLoad?FlowId='+flowId,
  })
}


// 获取流程签署人列表
export const flowSignerGet = flowId => {
  return request({
    method: 'GET',
    url: '/sign/api/FlowSigner/GetList?FlowId='+flowId,
  })
}