// 文章相关请求模块
import request from '@/utils/request'
// 查询个人签署账号请求
export const getPersonAccountById = id => {
  return request({
    method: 'GET',
    url: `/sign/api/PersonAccount?idNumber=${id}`
  })
}
// 创建个人签署账号请求
export const addPersonAccount = data => {
  return request({
    method: 'POST',
    url: '/sign/api/PersonAccount',
    data
  })
}
