<template>
  <div class="article-container sf">
    <div slot="header" class="clearfix sf-breadcrumb">
      <!--面包屑-->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>案件管理</el-breadcrumb-item>
        <el-breadcrumb-item>已办案件</el-breadcrumb-item>
        <el-breadcrumb-item>案件详情</el-breadcrumb-item>
        <el-breadcrumb-item>询问流程</el-breadcrumb-item>
      </el-breadcrumb>
      <!--/面包屑-->
    </div>
    <el-card class="filter-card">

      <!--数据表格筛选-->
      <el-form ref="form" :model="form" label-width="40px">
        <el-row :gutter="10">
          <el-col :span="16">
            <el-button type="primary"><i class="el-icon-download"></i>批量下载文件</el-button>
            <el-button type="primary"><i class="el-icon-view"></i>查看/下载证据</el-button>
          </el-col>
          <el-col :span="8" class="text-align-r">
            <el-button icon="el-icon-arrow-left" @click="goBack">返回案件详情</el-button>
          </el-col>
        </el-row>
      </el-form>
      <!--/数据表格筛选-->

      <!--数据表格-->
      <el-table :data="tableData" border style="width: 100%; margin: 20px 0">
        <el-table-column prop="xuHao" align="center" label="选择" width="60">
          <el-checkbox></el-checkbox>
        </el-table-column>
        <el-table-column prop="xuHao" align="center" label="序号" width="60">
        </el-table-column>
        <el-table-column prop="fileName" align="center" label="文件名称">
        </el-table-column>
        <el-table-column prop="signed" align="center" label="是否已签字">
        </el-table-column>
        <el-table-column prop="time" align="center" label="生成时间">
        </el-table-column>
        <el-table-column prop="peoOperate" align="center" label="操作" width="190">
          <div class="icon-box">
            <a @click="dialogPicVisible = true" class="btn-view"><i class="el-icon-view"></i>查看</a>
            <a href="#" class="btn-view"><i class="el-icon-download"></i>下载</a>
            <a href="#" class="btn-view"><i class="el-icon-printer"></i>打印</a>
          </div>
        </el-table-column>
      </el-table>
      <!--/数据表格-->
      <!--分页条-->
      <el-pagination class="pagination" background layout="prev, pager, next" :total="1000">
      </el-pagination>
      <!--/分页条-->
    </el-card>

    <el-dialog class="increaseForm" title="预览" append-to-body :visible.sync="dialogPicVisible"
               :close-on-click-modal="false">
      <div class="demo-image__preview">
        <el-image style="width: 400px; height: 400px" :src="url" :preview-src-list="srcList">
        </el-image>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'InquiryDetail',
  components: {},
  props: {},
  data () {
    return {
      tableData: [{
        xuHao: '1',
        fileName: '案件名称',
        signed: '行政案件',
        time: '案件简述'
      }],
      isShow: false,
      dialogPicVisible: false,
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      srcList: [
        'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
        'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
      ]
    }
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
  },
  methods: {
    loadArticles (page = 1) {
    },
    // 高级搜索框开关
    toggle () {
      this.isShow = !this.isShow
    },
    goBack () {
      // this.$router.push('/cased/detail')
      this.$router.push({
        path: '/cased/detail',
        query: {
          CaseId: this.$route.query.CaseId
        }
      })
    }
  }
}
</script>

<style>
.filter-card {
  margin-bottom: 30px;
}

.list-table {
  margin-bottom: 20px;
}

.article-cover {
  width: 60px;
  background-size: cover;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

.increaseForm {
  text-align: center;
}

.el-select .el-input {
  width: 130px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.sf-breadcrumb {
  padding-bottom: 13px;
  border-bottom: #cbddff 1px solid;
  margin-bottom: 20px;
}

.sf-breadcrumb .el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  font-weight: normal !important;
}

.text-align-r {
  text-align: right;
}

.sf-search-more {
  border: #d8e3f8 1px solid;
  border-radius: 4px;
  padding: 20px 20px 0 20px;
  margin-top: 20px;
  background: #e7effd;
}
</style>
