<template>
  <div class="article-container sf">
    <div slot="header" class="clearfix sf-breadcrumb">
      <!--面包屑-->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>案件管理</el-breadcrumb-item>
        <el-breadcrumb-item>待办案件</el-breadcrumb-item>
        <el-breadcrumb-item>案件详情</el-breadcrumb-item>
        <el-breadcrumb-item>证据材料</el-breadcrumb-item>
      </el-breadcrumb>
      <!--/面包屑-->
    </div>
    <el-card class="filter-card">

      <!--数据表格筛选-->
      <el-form ref="detailForm" :model="search" label-width="40px">
        <el-col class="text-align-r">
            <el-button class="el-icon-back" @click="back">返回</el-button>
          </el-col>
      </el-form>
      <!--/数据表格筛选-->

      <!--数据表格-->
      <el-table ref="tableRef" :height="tableHeight" :data="tableData" border v-loading="loading"
                style="width: 100%; margin: 20px 0">
        <el-table-column prop="CaseId" v-model="tableData.CaseId" align="center" label="序号" width="60">
          <template slot-scope="scope">
            <span v-text="getIndex(scope.$index)"> </span>
          </template>
        </el-table-column>
        <el-table-column prop="UploadTime" v-model="tableData.Name" align="center" label="材料上传时间" width="180">
        </el-table-column>
        <el-table-column prop="FilePath" v-model="tableData.PersonType" align="center" label="材料地址">
        </el-table-column>
        <el-table-column prop="peoOperate" align="center" label="操作" width="280">
          <div class="icon-box" slot-scope="scope">
            <a @click="downloadMaterial(scope.row.FilePath)" class="btn-view"><i class="el-icon-download"></i>材料下载</a>
          </div>
        </el-table-column>
      </el-table>
      <!--/数据表格-->
      <!--分页条-->
      <el-pagination
        class="pagination"
        background
        layout="total, sizes, prev, pager, next"
        :disabled="loading"
        @size-change="changePageSize"
        @current-change="changeCurrentPage"
        :current-page="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total">
      </el-pagination>
      <!--/分页条-->
    </el-card>
  </div>
</template>

<script>
import { getMaterialList} from '@/api/material'

export default {
  name: 'Detail',
  components: {
  },
  props: {},
  data () {
    return {
      PersonId: 0,
      tableData: [],
      input1: '',
      isShow: false,
      dialogPeoVisible: false,
      dialogInquiryVisible: false,
      loading: true,
      tableHeight: 500,
      dialogDetailEditVisible: false,
      // 查询条件
      search: {
        page: 1,
        pageSize: 10,
        keyword: '',
        CaseId: 0,
        PersonId: 0
      },
      totalPages: 0,
      // 分页信息
      pageInfo: {
        total: 0, // 总记录数，根据接口获取数据长度(注意：这里不能为空)
        pageSize: 10, // 默认每页数据量
        currentPage: 1
      },
      refresh1: true
    }
  },
  computed: {},
  watch: {},
  created () {
    this.loadMaterial(1)
  },
  mounted () {
    this.$nextTick(function () {
      this.tableHeight = window.innerHeight - this.$refs.tableRef.$el.getBoundingClientRect().top - 137
      const that = this
      window.onresize = function () {
        that.tableHeight = window.innerHeight - that.$refs.tableRef.$el.getBoundingClientRect().top - 137
      }
    })
  },
  methods: {
    refresh () {
      this.refresh1 = false
      this.$nextTick(() => {
        this.refresh1 = true
      })
    },
    loadMaterial (page = 1, tf = false) {
      // 展示加载中 loading
      this.loading = true
      this.search.CaseId = this.$route.query.CaseId
      this.search.PersonId = this.$route.query.PersonId
      console.log('search:', this.search)
      getMaterialList(this.search).then(res => {
        console.log(res, 'res')
        this.tableData = []
        if (res.data) {
          this.tableData = res.data
          console.log('this.tableData', this.tableData)
          // 新增完毕
          if (tf) {
            this.search.page = res.totalPages
          }
        }
        // 不管有没有数据，分页都要随着变化
        this.pageInfo.currentPage = res.currentPage
        this.pageInfo.pageSize = res.pageSize
        this.pageInfo.total = res.total
        // 关闭加载中 loading
        this.loading = false
      })
    },
    downloadMaterial(path){
        window.open('http://fw.zsyunsu.com/'+path)
    },
    resetForm () {
      this.search.name = null
      this.search.idCard = null
      this.search.cardType = null
    },
    changePageSize (pageSize) {
      console.log(`每页 ${pageSize} 条`)
      this.search.pageSize = pageSize // 改变每页显示的条数
      this.search.page = 1 // 注意：在改变每页显示的条数时，要将页码显示到第一页
      this.loadMaterial() // 点击每页显示的条数时，显示第一页
    },
    changeCurrentPage (page) {
      console.log(`当前第 ${page} 页`)
      this.search.page = page // 改变默认的页数
      this.loadMaterial()
    },
    getIndex ($index) {
      return (this.pageInfo.currentPage - 1) * this.pageInfo.pageSize + $index + 1
    },
    back(){
      this.$router.push({
        path: '/case/detail',
        query: {
          CaseId: this.$route.query.CaseId
        }
      })
    },
  }
}
</script>

<style>
.filter-card {
  margin-bottom: 30px;
}

.list-table {
  margin-bottom: 20px;
}

.article-cover {
  width: 60px;
  background-size: cover;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

.increaseForm {
  text-align: center;
}

.el-select .el-input {
  width: 130px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.sf-breadcrumb {
  padding-bottom: 13px;
  border-bottom: #cbddff 1px solid;
  margin-bottom: 20px;
}

.sf-breadcrumb .el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  font-weight: normal !important;
}

.text-align-r {
  text-align: right;
}

.sf-search-more {
  border: #d8e3f8 1px solid;
  border-radius: 4px;
  padding: 20px 20px 0 20px;
  margin-top: 20px;
  background: #e7effd;
}
</style>
